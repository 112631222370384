import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

import { listPayload, rightsAccess, showToast } from "../../../utils/helper";
import { settingActions } from "../../../redux/setting";
import { listSetting, updateSetting, deleteSetting } from "../../../service/setting";
import { startLoading, stopLoading } from "../../../redux/loader";

export const useSettingHook = () => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const settingData = useSelector((state) => state.setting.data);
    const loggedInUser = useSelector((state) => state.loggedInUser);

    const { accessModules } = loggedInUser;

    const [deleteId, setDeleteId] = useState("");
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    // pagination start
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const visibleRows = useMemo(() => {
        return settingData;
    }, [settingData]);

    const rights = useMemo(() => {
        return rightsAccess(accessModules, pathname);
    }, [accessModules, pathname]);

    const fetchSettingData = useCallback(
        async (searchValue = "") => {
          try {
            dispatch(startLoading());
            const payload = { searchText: searchValue };
            const body = listPayload(page, { ...payload });
    
            const response = await listSetting(body);
            if (response?.statusCode === 200) {
              const payload = response?.data;
              setCount(payload?.count);
              dispatch(settingActions.storeSetting(payload.rows));
            } else if (response?.statusCode === 404) {
              const payload = [];
              dispatch(settingActions.storeSetting(payload));
            }
          } catch (error) {
            showToast(error?.message, false);
          } finally {
            dispatch(stopLoading());
          }
    },[dispatch, page]);

    const searchSettingHandler = async (payload) => {
        try {
          fetchSettingData(payload.searchValue);
        } catch (error) {
          showToast(error.message, false);
        }
    };

    useEffect(() => {
        fetchSettingData();
    }, [fetchSettingData]);

    const deleteBtnClickHandler = (id) => {
        setDeleteId(id);
        setIsDeleteModalOpen(true);
    };

    const deleteHandler = async () => {
        try {
          // setIsDeleteModalOpen(false);
          dispatch(startLoading());
          const response = await deleteSetting(deleteId);
          if (response?.statusCode === 200) {
            showToast(response?.message || response?.messageCode, true);
            dispatch(settingActions.removeSetting({ id: deleteId }));
            setCount((prev) => prev - 1);
          } else {
            showToast(response?.message || response?.messageCode, false);
          }
        } catch (error) {
          showToast(error?.message, false);
        } finally {
          setIsDeleteModalOpen(false);
          dispatch(stopLoading());
        }
    };

    const changeStatusHandler = async (e, id) => {
        try {
          const payload = {
            isActive: e.target.checked,
            updatedBy: loggedInUser.id,
          };
          const response = await updateSetting(id, payload);
    
          if (response?.statusCode === 200) {
            showToast(response?.message, true);
            const payload2 = { id, status: payload.isActive };
            dispatch(settingActions.changeSettingStatus(payload2));
          } else {
            showToast(response?.message, false);
          }
        } catch (error) {
          showToast(error?.message, false);
        }
    };

    return {
        page,
        count,
        rights,
        visibleRows,
        isDeleteModalOpen,
        deleteHandler,
        handleChangePage,
        changeStatusHandler,
        searchSettingHandler,
        setIsDeleteModalOpen,
        deleteBtnClickHandler,
    }
}
