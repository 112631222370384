import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { startLoading, stopLoading } from "../../../redux/loader";
import { companyActions } from "../../../redux/company";
import { listPayload, rightsAccess, showToast } from "../../../utils/helper";
import { listCompany, updateCompany, deleteCompany } from "../../../service/company";

export const useCompanyHook = () => {

    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const companyData = useSelector((state) => state.company.data);
    const loggedInUser = useSelector((state) => state.loggedInUser);

    const { accessModules } = loggedInUser;
    
    const [ deleteId, setDeleteId ] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    // pagination start
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    
    const rights = useMemo(() => {
      return rightsAccess(accessModules, pathname);
    }, [accessModules, pathname]);
    
    const fetchCompanyData = useCallback(async (searchValue = '') => {
      try {
        dispatch(startLoading());
        const payload = { searchText: searchValue };
        const body = listPayload(page, { ...payload });

        const response = await listCompany(body);
        if (response?.statusCode === 200) {
          const payload = response?.data;
          setCount(payload?.count);
          dispatch(companyActions.storeCompany(payload.rows));
        } else if (response?.statusCode === 404) {
          dispatch(companyActions.storeCompany([]));
        }
      } catch(err) {
        showToast(err?.message, false);
      } finally {
        dispatch(stopLoading());
      }
    }, [dispatch, page]);
    
    const searchCompanyHandler = async (payload) => {
      try {
        fetchCompanyData(payload.searchValue);
      } catch (error) {
        showToast(error.message, false);
      }
    };

    const deleteBtnClickHandler = (id) => {
      setDeleteId(id);
      setIsDeleteModalOpen(true);
    };

    const deleteHandler = async () => {
      try {
        dispatch(startLoading());
        const response = await deleteCompany(deleteId);
        if (response?.statusCode === 200) {
          showToast(response?.message, true);
          dispatch(companyActions.removeCompany({ id: deleteId }));
          setCount((prev) => prev - 1);
        } else {
          showToast(response?.messageCode, false);
        }
      } catch (error) {
        showToast(error?.message, false);
      } finally {
        setIsDeleteModalOpen(false);
        dispatch(stopLoading());
      }
    };
    
    const changeStatusHandler = async (e, id) => {
      try {
        const payload = {
          isActive: e.target.checked,
          updatedBy: loggedInUser.id || 0,
        };
        const response = await updateCompany(id, payload);
  
        if (response?.statusCode === 200) {
          showToast(response?.message, true);
          // fetchCompanyData();
          const payload2 = { id, status: payload.isActive };
          dispatch(companyActions.changeCompanyStatus(payload2));
        } else {
          showToast(response?.message, false);
        }
      } catch (error) {
        showToast(error?.message, false);
      }
    };

    useEffect(() => {
      fetchCompanyData();
    }, [fetchCompanyData]);

    return {
      rows: companyData,
      page,
      count,
      rights,
      isDeleteModalOpen,
      deleteHandler,
      handleChangePage,
      changeStatusHandler,
      setIsDeleteModalOpen,
      searchCompanyHandler,
      deleteBtnClickHandler,
    }
}