import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: []
};

const SettingSlice = createSlice({
    name: 'setting',
    initialState: initialState,
    reducers: {
        storeSetting(state, action) {
            return { 
                data: action.payload
            }
        },
        removeSetting(state, action) {
            return {
                data: state.data.filter((row) => row.id !== action.payload.id)
            }
        },
        changeSettingStatus(state, action) {
            const updatedState = state.data.map((row) =>
              row.id === action.payload.id
                ? { ...row, isActive: action.payload.status }
                : { ...row }
            );
            return { data: updatedState };
        },
    }
});

export const settingActions = SettingSlice.actions;
export default SettingSlice.reducer;