import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: []
};

const ItemSlice = createSlice({
    name: 'item',
    initialState: initialState,
    reducers: {
        storeItem(state, action) {
            return { 
                data: action.payload
            }
        },
        removeItem(state, action) {
            return {
                data: state.data.filter((row) => row.id !== action.payload.id)
            }
        },
        changeItemStatus(state, action) {
            const updatedState = state.data.map((row) =>
              row.id === action.payload.id
                ? { ...row, isActive: action.payload.status }
                : { ...row }
            );
            return { data: updatedState };
        },
    }
});

export const ItemActions = ItemSlice.actions;
export default ItemSlice.reducer;