import {
    COMPANY_API
} from "../utils/constant";

import { post, put, remove, get } from "./webRequest";

export const listCompany = async (body) => {
    return await post(`${COMPANY_API}/list`, body);
}

export const getCompanyById = async (id) => {
    return await get(`${COMPANY_API}/${id}`);
}

export const getCompanyByPayload = async (body) => {
    return await post(`${COMPANY_API}/get`, body);
}

export const saveCompany = async (body) => {
    return await post(`${COMPANY_API}`, body);
}

export const updateCompany = async (id, body) => {
    return await put(`${COMPANY_API}/${id}`, body);
}

export const deleteCompany = async (id) => {
    return await remove(`${COMPANY_API}/${id}`);
}