import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { listPayload, showToast } from "../../../utils/helper";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createUser, getUserById, updateUser } from "../../../service/users";
import { getRolesList } from "../../../service/roles";
import { listCompany } from "../../../service/company";
import { startLoading, stopLoading } from "../../../redux/loader";

export const useAddEditUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();
  const loggedInUser = useSelector((state) => state.loggedInUser);

  const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);

  const [roles, setRoles] = useState([]);
  const [company, setCompany] = useState([]);
  
  const { setValue, handleSubmit, control, watch } = useForm({
    defaultValues: {
      roleID: "",
      companyID: loggedInUser.px_company.id,
      firstName: "",
      lastName: "",
      userName: "",
      password: "",
      phoneNumber: "",
      email: "",
    },
    mode: "onBlur",
  });

  const firstName = watch("firstName");
  const lastName = watch("lastName");

  useMemo(() => {
    const formattedFirstName =
      firstName.charAt(0).toUpperCase() + firstName.slice(1);
    const formattedLastName =
      lastName.charAt(0).toUpperCase() + lastName.slice(1);

    const newUserName = formattedFirstName + formattedLastName;
    setValue("userName", newUserName.replace(/\s+/g, ""));
  }, [firstName, lastName, setValue]);

  const isSuperAdmin = useMemo(() => {
    if(loggedInUser && loggedInUser.px_role) {
      return ['super admin'].includes(loggedInUser.px_role.name.toLowerCase());
    }
  }, [loggedInUser]);

  // get role list
  useEffect(() => {
    try {
      const fetchRolesData = async () => {
        const body = listPayload(0, { isActive: true }, 1000);
        const [
          responseRole,
          responseCompany
        ] = await Promise.all([
          getRolesList(body),
          listCompany(body)
        ]);

        if(responseCompany.statusCode === 200) {
          const payload = responseCompany?.data?.rows;
          setCompany(payload);
        } else {
          setCompany([]);
        }

        if (responseRole.statusCode === 200) {
          const payload = responseRole?.data?.rows;
          setRoles(payload);
        } else if (responseRole.statusCode === 404) {
          const payload = [];
          setRoles(payload);
        }
      };
      fetchRolesData();
    } catch (error) {
      showToast(error.message, false);
    }
  }, []);

  const onSubmit = async (data) => {
    try {
      dispatch(startLoading());

      const payload = {
        companyID: data.companyID,
        roleID: data.roleID,
        firstName: data.firstName,
        lastName: data.lastName,
        userName: data.userName,
        phoneNumber: data.phoneNumber,
        email: data.email,
      };

      const response = !isEditMode
        ? await createUser({
            ...payload,
            password: data.password,
            createdBy: loggedInUser.id,
          })
        : await updateUser({ ...payload, updatedBy: loggedInUser.id }, id);

      if (response?.statusCode === 200) {
        showToast(response.message, true);
        navigate("/user");
      } else {
        showToast(response?.messageCode, false);
      }
    } catch (error) {
      showToast(error.message, false);
    } finally {
      dispatch(stopLoading());
    }
  };

  const fetchEditUserData = useCallback(async () => {
    try {
      if (id) {
        dispatch(startLoading());
        const response = await getUserById(id);
        if (response.statusCode === 200) {
          setValue("firstName", response.data.firstName);
          setValue("lastName", response.data.lastName);
          setValue("userName", response.data.userName);
          setValue("roleID", response.data.roleID);
          setValue("email", response.data.email);
          setValue("phoneNumber", response.data.phoneNumber);
          setValue("companyID", response.data?.companyID);
        } else {
          showToast(response.message, false);
        }
      }
    } catch (error) {
      showToast(error.message, false);
    } finally {
      dispatch(stopLoading());
    }
  }, [id, dispatch, setValue]);

  const cancelHandler = () => {
    navigate("/user");
  };

  const isEditMode = useMemo(() => {
    return id && typeof parseInt(id) === 'number' ? true : false
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if(id) {
      fetchEditUserData();
    }
    // eslint-disable-next-line
  }, [id]);

  return {
    role: 'admin', //loggedInUser?.px_role?.name.toLowerCase(),
    userId: id,
    roles,
    company,
    control,
    isEditMode,
    isSuperAdmin,
    isChangePasswordOpen,
    onSubmit,
    handleSubmit,
    cancelHandler,
    setIsChangePasswordOpen,
  };
};
