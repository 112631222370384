import React from "react";
import { Controller } from "react-hook-form";

import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";


import { useAddEditItemHook } from "./hook/useAddEditItem";

const AddEditItem = () => {
    const {
        control,
        company,
        isEditMode,
        isSuperAdmin,
        onSubmit,
        handleSubmit,
        cancelHandler
    } = useAddEditItemHook();

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box className="card">
                    <FormGroup className="form-field">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="companyID"
                                    control={control}
                                    render={({
                                        field: { onBlur, onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <Autocomplete
                                            freeSolo
                                            size="small"
                                            id="companyID"
                                            disabled={!isSuperAdmin}
                                            options={company || []}
                                            getOptionLabel={(option) => option.name ? option.name : ''}
                                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                            value={company?.find((option) => option.id === value) ?? ''}
                                            onBlur={onBlur}
                                            onChange={(_event, value) => {
                                                if (value) {
                                                    onChange(value?.id)
                                                } else {
                                                    onChange(null);
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Select Company"
                                                    error={!!error}
                                                    helperText={error?.message}
                                                />
                                            )}
                                        />
                                    )}
                                    rules={{
                                        required: "Please Select Company",
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Controller
                                    name="name"
                                    control={control}
                                    render={({
                                        field: { onBlur, onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <FormControl
                                            size="small"
                                            variant="standard"
                                            className="form-control"
                                        >
                                            <TextField
                                                label="Item name"
                                                size="small"
                                                name="name"
                                                value={value}
                                                onChange={(e) => {
                                                    // if (e.target.value.length < 15) {
                                                    onChange(e.target.value.toUpperCase())
                                                    // }
                                                }}
                                                onBlur={onBlur}
                                                error={!!error}
                                                helperText={error?.message}
                                            />
                                        </FormControl>
                                    )}
                                    rules={{
                                        required: "Item name field required",
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="rate"
                                    control={control}
                                    render={({
                                        field: { onBlur, onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <FormControl
                                            size="small"
                                            variant="standard"
                                            className="form-control"
                                        >
                                            <TextField
                                                type="number"
                                                label="Rate"
                                                size="small"
                                                name="rate"
                                                value={value}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                error={!!error}
                                                helperText={error?.message}
                                            />
                                        </FormControl>
                                    )}
                                    rules={{
                                        required: "Rate is required",
                                        pattern: { value: /^[0-9]+$/, message: "Only digit" },
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </FormGroup>
                </Box>
                <Grid container spacing={3} sx={{ marginTop: "6px" }}>
                    <Grid item md={1.5}>
                        <Button type="submit" className="btn btn-tertiary">
                            {isEditMode ? "Update" : "Save"}
                        </Button>
                    </Grid>
                    <Grid item md={1.5}>
                        <Button className="btn btn-cancel" onClick={cancelHandler}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

export default AddEditItem;