import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';

import { getBillList } from "../../../service/bill";
import { getCustomerList } from "../../../service/customer";
import { getPaymentList } from "../../../service/payment";
import { startLoading, stopLoading } from "../../../redux/loader";
import { PrintReportContent } from "../../../components/PrintContent";
import { listPayload, showToast, showTwoDecimal } from "../../../utils/helper";

const useBillReport = () => {
    const dispatch = useDispatch();
    const loggedInUser = useSelector((state) => state.loggedInUser);

    const [dateRange, setDateRange] = useState([new Date(), new Date()]);
    const [customerList, setCustomerList] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    const handleFetchExport = async () => {
        try {
            if (!(dateRange.length && dateRange[0] && dateRange[1])) {
                showToast('Select From & To Date', false);
                return;
            }
            if (!selectedCustomer) {
                showToast('Select Customer', false);
                return;
            }
            dispatch(startLoading());
            const body = {
                companyID: loggedInUser.companyID,
                startDate: moment(dateRange[0]).format('yyyy-MM-DD'),
                endDate: moment(dateRange[1]).format('yyyy-MM-DD'),
                customerID: selectedCustomer
            }
            const [
                responseBill,
                responsePayment
            ] = await Promise.all([
                getBillList(listPayload(0, body, 20000000)),
                getPaymentList(listPayload(0, body, 2000000))
            ]);
            const billList = (responseBill && responseBill.statusCode === 200) ? responseBill.data.rows : [];
            const paymentList = (responsePayment && responsePayment.statusCode === 200) ? responsePayment.data.rows : [];
            const customerDetail = customerList.find((customer) => customer.id === selectedCustomer);
            const printDetail = {
                company: {
                    name: loggedInUser?.px_company?.name,
                    address: loggedInUser?.px_company?.address,
                    phoneNo: loggedInUser?.px_company?.phoneNumber
                },
                customerName: customerDetail?.name,
                startDate: moment(dateRange[0]).format('DD/MM/yyyy'),
                endDate: moment(dateRange[1]).format('DD/MM/yyyy'),
                debitList: billList && billList.length ? billList?.map((bill) => {
                    return {
                        amount: showTwoDecimal(bill?.grandTotal),
                        date: bill?.date,
                        billNo: bill?.billNo
                    }
                }) : [{
                    amount: 0,
                    date: moment(new Date()).format('yyyy-MM-DD'),
                    billNo: 'N/A'
                }],
                creditList: paymentList && paymentList.length ? paymentList?.map((payment) => {
                    return {
                        amount: showTwoDecimal(payment.amount),
                        date: payment.date,
                        remarks: payment.remarks
                    }
                }) : [{
                    amount: 0,
                    date: moment(new Date()).format('yyyy-MM-DD'),
                    remarks: 'N/A'
                }],
                debitTotal: showTwoDecimal(billList.reduce((acc, obj) => (acc + obj?.grandTotal), 0)),
                creditTotal: showTwoDecimal(paymentList.reduce((acc, obj) => (acc + obj?.amount), 0)),
            }
            const printWindow = window.open("", "_blank", "popup=yes,menubar=no,toolbap=no");
            if (printWindow && printWindow.document) {
                printWindow.document.write(PrintReportContent(printDetail));
                printWindow.document.close();
                printWindow.onload = () => {
                    printWindow.print();
                    printWindow.close();
                };
            }
        } catch (error) {
            showToast(error.message, false);
        } finally {
            dispatch(stopLoading());
        }
    }

    useEffect(() => {
        (async () => {
            const payload = listPayload(0, { isActive: true, isDeleted: false, companyID: loggedInUser.companyID }, 100000);
            const response = await getCustomerList(payload);
            if (response && response.statusCode === 200) {
                setCustomerList(response?.data?.rows);
            } else {
                setCustomerList([]);
            }
        })();
        // eslint-disable-next-line
    }, []);

    return {
        dateRange,
        customerList,
        selectedCustomer,
        setDateRange,
        handleFetchExport,
        setSelectedCustomer,
    }
}

export default useBillReport;