import React from "react";
import { QRCodeSVG } from "qrcode.react";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

const QrCode = ({
    open,
    qrString = '',
    handleClose
}) => {
    console.log(qrString);
    return (
        <>
            <Modal
                disableEscapeKeyDown
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box className="modal-wrapper modal-bg">
                        <Typography variant="subtitle2">Whats app Web Connect</Typography>
                        <br/>
                        {qrString && qrString.length > 0 ?
                            <QRCodeSVG width="100%" size={256} value={qrString} />
                        : 
                        null}
                    </Box>
                </Fade>
            </Modal>
        </>
    )
}

export default QrCode;