import { combineReducers } from "@reduxjs/toolkit";
import CustomerReducer from "./customer";
import PaymentTypeReducer from "./paymentType";
import LoggedInUserReducer from "./loggedInUser";
import LoaderReducer from "./loader";
import UserReducer from "./users";
import RoleReducer from "./role";
import ModuleReducer from "./module";
import BillReducer from "./bill";
import ReportReducer from "./report";
import CompanyReducer from "./company";
import ItemReducer from "./item";
import SettingReducer from "./setting";
import PaymentReducer from "./payment";
import ReminderReducer from "./reminder";

const rootReducer = combineReducers({
  loader: LoaderReducer,
  customer: CustomerReducer,
  paymentType: PaymentTypeReducer,
  loggedInUser: LoggedInUserReducer,
  users: UserReducer,
  role: RoleReducer,
  module: ModuleReducer,
  bill: BillReducer,
  report: ReportReducer,
  company: CompanyReducer,
  item: ItemReducer,
  setting: SettingReducer,
  payment: PaymentReducer,
  reminder: ReminderReducer
});

export default rootReducer;
