import {
    ITEM_API
} from "../utils/constant";

import { post, put, remove, get } from "./webRequest";

export const dropdownItem = async () => {
    return await get(`${ITEM_API}/dropdown`);
}

export const listItem = async (body) => {
    return await post(`${ITEM_API}/list`, body);
}

export const getItemById = async (id) => {
    return await get(`${ITEM_API}/${id}`);
}

export const getItemByPayload = async (body) => {
    return await post(`${ITEM_API}/get`, body);
}

export const saveItem = async (body) => {
    return await post(`${ITEM_API}`, body);
}

export const updateItem = async (id, body) => {
    return await put(`${ITEM_API}/${id}`, body);
}

export const deleteItem = async (id) => {
    return await remove(`${ITEM_API}/${id}`);
}