import React from "react";
import { DateRangePicker } from "rsuite";
import 'rsuite/dist/rsuite.min.css';

import Autocomplete from "@mui/material/Autocomplete";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import useBillReport from "./billReport.hook";

const BillReport = () => {
    const {
        dateRange,
        customerList,
        setDateRange,
        handleFetchExport,
        setSelectedCustomer
    } = useBillReport();
    return (
        <Card>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                        <DateRangePicker style={{ width: '100%'}} value={dateRange} onChange={(value) => setDateRange(value)} />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Autocomplete
                            freeSolo
                            size="small"
                            disablePortal
                            id="customerID"
                            options={customerList || []}
                            getOptionLabel={(option) => option?.name}
                            onChange={(_e, newValue) => {
                                setSelectedCustomer(newValue?.id);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Customer"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={1}>
                        <Button size="small" className="btn btn-tertiary" onClick={handleFetchExport}>Export</Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default BillReport;