import React from "react";
import { PiUsersThree } from "react-icons/pi";
import { TbFileInvoice } from "react-icons/tb";
import { FaCity } from "react-icons/fa";
import { FaRupeeSign } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Switch from "@mui/material/Switch";
import { FiEdit3, FiTrash2 } from "react-icons/fi";

import ConfirmationModal from "../../components/ConfirmationModal";
import AddEditReminder from "../../components/AddEditReminder";
import ActivityCard from "../../components/ActivityCard";
import { useHome } from "./hook/useHome";

import 'rsuite/dist/rsuite.min.css';

const switchStyles = {
  color: "var(--color-black)",
  "&.MuiChecked": {
    color: "green",
  },
  "&.MuiChecked + .MuiSwitchTrack": {
    backgroundColor: "lightgreen",
  },
};

const Home = () => {
  const {
    page,
    count,
    control,
    details,
    visibleRows,
    customerList,
    isSuperAdmin,
    isDeleteModalOpen,
    isReminderModalOpen,
    onSubmit,
    handleEdit,
    handleSubmit,
    deleteHandler,
    toggleReminder,
    handleChangePage,
    changeStatusHandler,
    setIsDeleteModalOpen,
    deleteBtnClickHandler,
    setIsReminderModalOpen
  } = useHome();
  let index = page * 10;

  return (
    <>
      <Box className="card">
        {/* activity card */}
        <Box className="activity-card-wrapper mb-24">
          <Grid container spacing={3}>
            <ActivityCard
              ActivityTitle={"Customer"}
              ActivityNumber={details?.counts?.customerCount}
              ActivityIcon={<PiUsersThree />}
              path="add-customer"
            />
            {isSuperAdmin &&
              <ActivityCard
                ActivityTitle={"Company"}
                ActivityNumber={details?.counts?.companyCount}
                ActivityIcon={<FaCity />}
                path="add-company"
              />
            }
            <ActivityCard
              ActivityTitle={"Payment"}
              ActivityNumber={details?.counts?.paymentCount}
              ActivityIcon={<FaRupeeSign />}
              path="add-payment"
            />
            <ActivityCard
              ActivityTitle={"Bill"}
              ActivityNumber={details?.counts?.billCount}
              ActivityIcon={<TbFileInvoice />}
              path="create-bill"
            />
          </Grid>
        </Box>
      </Box>
      <br/>
      <Box className="card">
        <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="subtitle1" fontSize={22} fontWeight={600}>Reminder List</Typography>
          <Button
            size="small"
            component={"button"}
            className="btn btn-tertiary"
            onClick={() => setIsReminderModalOpen(true)}
          >
            <FiPlus /> &nbsp; <p>Add</p>
          </Button>
        </Box>
        <br/>
        <TableContainer className="table-wrapper">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Reminder Date</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell>Reminder Taken</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {visibleRows.length ? (
                visibleRows.map((row) => {
                  return (
                    <TableRow key={"customer_" + row.id}>
                      <TableCell align="left">{(index += 1)}</TableCell>
                      <TableCell align="left">{row.nextDate}</TableCell>
                      <TableCell align="left">{row?.px_company?.name}</TableCell>
                      <TableCell align="left">{row?.px_customer?.name}</TableCell>
                      <TableCell align="left">
                        <Switch
                          style={switchStyles}
                          checked={row.isReminder}
                          onChange={(e) => changeStatusHandler(e, row.id)}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Box className="table-action-btn">
                          <Button disabled={row.isReminder} className="btn btn-primary" onClick={() => handleEdit(row)}>
                            <FiEdit3 size={15} />
                          </Button>
                          <Button disabled={row.isReminder} className="btn btn-primary" onClick={deleteBtnClickHandler.bind(null, row.id)} >
                            <FiTrash2 size={15} />
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell sx={{ textAlign: "center" }} colSpan={6}>
                    No customers Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={count}
          rowsPerPage={10}
          page={page}
          onPageChange={handleChangePage}
        />
      </Box>

      {isReminderModalOpen &&
        <AddEditReminder
          control={control}
          open={isReminderModalOpen}
          customerList={customerList || []}
          closeModal={toggleReminder}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
        />
      }

      {isDeleteModalOpen && (
        <ConfirmationModal
          isDeleteModalOpen={isDeleteModalOpen}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          title="payment"
          deleteHandler={deleteHandler}
        />
      )}
    </>
  );
};

export default Home;
