import moment from 'moment';
import { showTwoDecimal, convertAmountToWords } from '../utils/helper';
const { REACT_APP_CGST, REACT_APP_SGST } = process.env;

export const PrintContent = (billData, branchData, isShowSecondPage = true) => {
  const date = moment(billData.date || new Date()).format('DD/MM/yyyy');
  /*new Date(billData?.date).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });*/
  const time = moment(billData.date || new Date()).format('hh:mm:ss A');
  /*new Date(billData?.date).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });*/

  return `
  <html>
    <head>
      <title>G${billData.billNo}</title>
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet">
      <style>
        *{
          font-family: 'Poppins', sans-serif;
         font-weight: bold;
        }
        @media print {
          @page {
            size: 150mm
          }
        }
      </style>
    </head>
    <body>
      <div style="padding: 0mm; margin: 0 auto; width: 88mm;">

          <div style="page-break-after: always; border: 0px solid black; min-height: max-content;">
            <p style="text-transform: capitalize;font-size: 20px; font-weight: 600; margin: 0px;text-align: center; margin-bottom: 0px">${branchData.title
    }</p>
            <p style="text-transform: capitalize; font-size: 12px; margin: 0px; text-align: center;">
              ${branchData.address}
            </p>
            <div style="display: flex; justify-content: space-between;margin-top: 7px;">
              <div>
                <p style="text-align: start; margin: 0px; font-size: 14px;">Ph: ${branchData.phone1
    }, ${branchData.phone2}  </p>
                <p style="text-align: start; margin: 0px; font-size: 14px;">Bill No: ${billData.billNo
    }</p>
              </div>
              <div>
                <p style="text-align: start; margin: 0px; font-size: 14px;">Date: ${date}</p>
                <p style="text-align: start; margin: 0px; font-size: 14px;">Time: ${time}</p>
              </div>
            </div>
            <div>
              <p style="text-align: start; margin: 0px; font-size: 14px;">
                Customer Name : Cash Customer
              </p>
            </div>
            <div style="width: 100%;display: flex;justify-content: space-between;margin-top: 7px;">
              
              <div>
                <p style="text-align: start; margin: 0px; font-size: 14px;">Name: ${billData.customer
    }</p>
                <p style="text-align: start; margin: 0px; font-size: 14px;">Ph: ${billData.phone
    }</p>
              </div>
              ${billData.isShowGst ?
      `<div>
                  <p style="text-align: start; margin: 0px; font-size: 14px;">GST NO: <br/>${billData.gstNo}</p>
                </div>`
      : ''}
            </div>
            <div style="width: 100%; border-top: 1px dashed black;margin: 0;margin-top: 7px;"></div>
            <table style="width: 100%;">
              <thead>
                <tr style="text-align:center;font-size: 14px;border: 1px solid black;">
                  <td>SR</td>
                  <td>Item Name</td>
                  <td>Qty</td>
                  <td>Rate</td>
                  <td>Value</td>
                </tr>
              </thead>
              <tbody>
                ${billData.detail?.map(
        (row, index) =>
          `<tr style="text-align:center;font-size: 14px;">
                      <td>${index + 1}</td>
                      <td>${row.item}</td>
                      <td>${row.quantity}</td>
                      <td>${showTwoDecimal(row.total)}</td>
                      <td>${showTwoDecimal(row.total)}</td>
                    </tr>`
      )}
              </tbody>
            </table>
            <div style="width: 100%; border-top: 1px dashed black;border-bottom: 1px dashed black; display: flex; justify-content: end;font-size: 14px;">
              <p style="margin: 5px 0px; margin-right: 10px; font-weight: 600;">Sub Total: </p>
              <p style="margin: 5px 0px; margin-right: 4px; font-weight: 600; text-align: end;">${showTwoDecimal(billData.subTotal)
    }</p>
            </div>
            ${billData.isShowGst ?
      `<div style="width: 100%; border-top: 1px dashed black;border-bottom: 1px dashed black; display: flex; justify-content: end;font-size: 14px;">
                ${billData.cgst &&
      `<p style="margin: 5px 0px; margin-right: 10px; font-weight: 600;">CGST (${REACT_APP_CGST}%): </p>
                  <p style="margin: 5px 0px; margin-right: 4px; font-weight: 600; text-align: end;">${showTwoDecimal(billData.cgst)
      },</p>`
      }
                ${billData.sgst &&
      `<p style="margin: 5px 0px; margin-right: 10px; font-weight: 600;">SGST (${REACT_APP_SGST}%): </p>
                  <p style="margin: 5px 0px; margin-right: 4px; font-weight: 600; text-align: end;">${showTwoDecimal(billData.sgst)
      }</p>`
      }
              </div>`
      : ''}
            <div style="width: 100%;border-bottom: 1px dashed black; display: flex; justify-content: end;font-size: 20px;">
              <p style="margin: 5px 0px; margin-right: 14px; font-weight: 600;">Grand Total : </p>
              <p style="margin: 5px 0px; margin-right: 4px; font-weight: 600; text-align: end;">${showTwoDecimal(billData.total)
    }</p>
            </div>
            <div style="width: 100%;border-bottom: 1px dashed black; display: flex; justify-content: start;font-size: 10px;">
              <p style="margin: 5px 0px;">Amount in Words: </p>
              <p style="margin: 5px 0px; margin-left: 10px;">${convertAmountToWords(billData.total).toUpperCase()
    } RUPEES</p>
            </div>
            <div style="width: 100%;border-bottom: 1px dashed black; display: flex; justify-content: start; flex-direction: column; font-size: 14px;">
              <div>
                ${billData.payment.toLowerCase().includes("card")
      ? `<p style={{margin: "5px 0px"}}> CARD NO : ${billData.cardNo}</p>`
      : ""
    }
              </div>
              <div style="display: flex;">
                <p style="margin: 5px 0px; ">${billData.payment}: </p>
                <p style="margin: 5px 0px; margin-left: 10px;">${showTwoDecimal(billData.total)
    }</p>
              </div>
            </div>
            <div style="width: 100%;border-bottom: 1px dashed black; display: flex; justify-content: start; flex-direction: column; font-size: 14px;">
              <p style="text-align: center; margin: 5px;font-size: 12px;">
                * Terms and Conditions Apply
              </p>
            </div>
              <p style="text-align: center; margin: 0;font-size: 12px;margin-top: 7px;">
              * AFTER PAID AMOUNT CANNOT BE REFUND
              </p>
              <p style="text-align: center; margin: 0;font-size: 12px;">Thank You.... Visit Again....</p>
          </div>
          ${isShowSecondPage ?
      `<div style="height: max-content; border: 0px solid black;">
              <table style="font-size: 20px;">
                <tbody>
                  <tr>
                    <td style="padding: 10px 0;">Date</td>
                    <td style="padding: 0px 18px;">:</td>
                    <td>${date}</td>
                  </tr>
                  <tr>
                    <td style="padding: 10px 0;">Time</td>
                    <td style="padding: 0px 18px;">:</td>
                    <td>${time}</td>
                  </tr>
                  <tr>
                    <td style="padding: 10px 0;">Customer</td>
                    <td style="padding: 0px 18px;">:</td>
                    <td>${billData.customer}</td>
                  </tr>
                  <tr>
                    <td style="padding: 10px 0;">Room No</td>
                    <td style="padding: 0px 18px;">:</td>
                    <td>${billData.roomNo}</td>
                  </tr>
                  <tr>
                    <td style="padding: 10px 0;">Service Name</td>
                    <td style="padding: 0px 18px;">:</td>
                    <td>${billData.detail
        ?.map((row, index) => row.item)
        .join(", ")}</td>
                  </tr>
                  <tr>
                    <td style="padding: 10px 0;">Therapists Name</td>
                    <td style="padding: 0px 18px;">:</td>
                    <td>${billData.staff}</td>
                  </tr>
                </tbody>
              </table>
            </div>`
      : ''}
      </div>
    </body>
  </html>
  `;
};

export const PrintReportContent = (detail) => {
  return `
    <!doctype html>
    <html lang="en-US">
      <head>
        <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
        <style type="text/css">
          a:hover {text-decoration: underline !important;}
        </style>
      </head>
      <body marginheight="0" topmargin="0" marginwidth="0" style="margin: 0px; background-color: #f2f3f8;" leftmargin="0">
        <table cellspacing="0" border="0" cellpadding="0" width="100%" bgcolor="#f2f3f8"
          style="@import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,700|Open+Sans:300,400,600,700); font-family: 'Open Sans', sans-serif;">
          <tr>
            <td>
              <table style="background-color: #ffffff; max-width:670px; padding-bottom: 25px;  margin:0 auto; height: 100%;" width="100%" height="100%" border="0"
                align="center" cellpadding="0" cellspacing="0">
                <tr>
                  <td style="text-align:center; padding-top: 25px;">
                    <h1 style="margin-bottom: 0;">${detail?.company?.name}</h1>
                    <p style="font-size: 14px; margin-top: 4px;" >${detail?.company?.address} <br> PH :- ${detail?.company?.phoneNo}</p>
                  </td>
                </tr>
                <tr>
                  <td style="text-align: start; font-size: 14px; font-weight: 600; padding-top: 5px; padding:0 25px;">Account Statement For ${detail.customerName}</td>
                </tr>
                <tr>
                  <td style="padding:0 25px; text-align: start; ">
                      <p style="color:#1e1e2d; font-weight:600; margin:0;font-size:14px; padding-bottom: 5px; padding-top: 5px;">From ${detail.startDate} To ${detail.endDate}</p>
                  </td>
                </tr>
                <tr>
                  <td style="padding: 0 25px; ">
                    <table style="width: 100%; border-bottom: 1px solid #000; border-top: 1px solid #000; margin-top: 15px; ">
                      <tr>
                        <td style="width: 50%;">
                          <div style="text-align: center;">
                            <p style="margin: 5px 0px;">Credit Particulars</p>    
                          </div>
                        </td>
                        <td style="width: 50%;">
                          <div style="text-align: center;">
                            <p style="margin: 5px 0px;">Debit Particulars</p>
                          </div>
                        </td>
                      </tr>
                    </table>
                    <table style="width: 100%;">
                      <tr>
                        <td style="text-align: center; vertical-align: top;">
                          ${detail.creditList?.map((credit) => (
                            `<p style="margin: 3px 0px; font-size: 14px;">${credit.amount}/- ${credit.date} ${credit.remarks}</p>`
                          )).join('')}
                        </td>
                        <td style="text-align: center;">
                        ${detail.debitList?.map((debit) => (`
                          <div>
                            <p style="margin: 3px 0px; font-size: 14px;">${debit.amount}/- ${debit.date} Sale</p>
                            <p style="margin: 3px 0px; font-size: 14px; text-align: center;">Bill No &nbsp;&nbsp;&nbsp; ${debit.billNo}</p>
                          </div>
                        `)).join('')}
                        </td>
                      </tr>
                    </table>
                    <table  style=" border-top: 1px solid #000; padding: 5px 0px;" >
                      <tr>
                        <td>${detail.creditTotal}/-</td>
                      </tr>
                      <tr>
                        <td style="font-weight: 600;">${(detail.debitTotal - detail.creditTotal)}/- DB Closing Balance</td>
                      </tr>
                    </table>
                    <table style=" width: 100%; padding: 0px 0px;" >
                      <tr>
                        <td>
                          <p style=" border-bottom: 2px solid #000; border-top: 2px solid #000; padding: 5px 0px; font-weight: 600; margin: 0; width: fit-content;"> ${detail.debitTotal}/-</p>
                        </td>
                        <td style="padding-left: 60px;"></td>
                        <td>
                          <p style=" border-bottom: 2px solid #000; border-top: 2px solid #000; padding: 5px 0px; font-weight: 600;  margin: 0; width: fit-content;">${detail.debitTotal}/-</p>
                        </td>
                      </tr>    
                    </table>    
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </body>
    </html>
  `;
};

export const PrintBillContent = (detail) => {
  return `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html xmlns="http://www.w3.org/1999/xhtml" xml:lang="en" lang="en">
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <style type="text/css">
          * {
            margin: 0;
            padding: 0;
            text-indent: 0;
          }

          .s1 {
            color: black;
            font-family: Calibri, sans-serif;
            font-style: normal;
            font-weight: bold;
            text-decoration: none;
            font-size: 12pt;
          }

          .s2 {
            color: black;
            font-family: Calibri, sans-serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 8pt;
          }

          .s3 {
            color: black;
            font-family: Calibri, sans-serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 8pt;
          }

          .s4 {
            color: black;
            font-family: Calibri, sans-serif;
            font-style: normal;
            font-weight: bold;
            text-decoration: none;
            font-size: 8pt;
          }

          .s5 {
            color: black;
            font-family: Calibri, sans-serif;
            font-style: normal;
            font-weight: bold;
            text-decoration: none;
            font-size: 8.5pt;
          }

          .s6 {
            color: black;
            font-family: Calibri, sans-serif;
            font-style: normal;
            font-weight: bold;
            text-decoration: none;
            font-size: 6.5pt;
          }

          .s7 {
            color: black;
            font-family: Calibri, sans-serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 6.5pt;
          }

          .s8 {
            color: black;
            font-family: Calibri, sans-serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 7.5pt;
          }

          table,
          tbody {
            vertical-align: top;
            overflow: visible;
          }
        </style>
      </head>
      <body>
        <table style="border-collapse:collapse;margin-left:6.24879pt" cellspacing="0">
        <tr style="height:16pt">
          <td style="width:567pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="9">
            <p class="s1" style="padding-left: 1pt;padding-right: 1pt;text-indent: 0pt;line-height: 14pt;text-align: center;">${detail.company.name}</p>
          </td>
        </tr>
        <tr style="height:14pt">
          <td style="width:567pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="9">
            <p class="s2" style="padding-top: 1pt;padding-right: 1pt;text-indent: 0pt;text-align: center;">
              ${detail.company.address}
            </p>
          </td>
        </tr>
        <tr style="height:14pt">
          <td style="width:567pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="9">
            <p style="padding-top: 1pt;padding-right: 1pt;text-indent: 0pt;text-align: center;">
              
              <a href="mailto:${detail.company.email}" class="s3" target="_blank">
                Phone : - +91 ${detail.company.phoneNo},  
                Email : - ${detail.company.email}
              </a>
            </p>
          </td>
        </tr>
        <tr style="height:14pt">
          <td style="width:73pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;padding-top: 2pt;">
            <p class="s4" style="padding-left: 1pt;text-indent: 0pt;text-align: left;">Debit Memo</p>
          </td>
          <td style="width:17pt;border-top-style:solid;border-top-width:1pt;border-bottom-style:solid;border-bottom-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
          </td>
          <td style="width:69pt;border-top-style:solid;border-top-width:1pt;border-bottom-style:solid;border-bottom-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
          </td>
          <td style="width:17pt;border-top-style:solid;border-top-width:1pt;border-bottom-style:solid;border-bottom-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
          </td>
          <td style="width:152pt;border-top-style:solid;border-top-width:1pt;border-bottom-style:solid;border-bottom-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
          </td>
          <td style="width:80pt;border-top-style:solid;border-top-width:1pt;border-bottom-style:solid;border-bottom-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
          </td>
          <td style="width:79pt;border-top-style:solid;border-top-width:1pt;border-bottom-style:solid;border-bottom-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
          </td>
          <td style="width:49pt;border-top-style:solid;border-top-width:1pt;border-bottom-style:solid;border-bottom-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
          </td>
          <td style="width:31pt;border-top-style:solid;border-top-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt;padding-top: 2pt;">
            <p class="s4" style="text-indent: 0pt;text-align: left;">Original</p>
          </td>
        </tr>
        <tr style="height:27pt">
          <td style="width:408pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="6">
            <p class="s4" style="padding-left: 1pt;text-indent: 0pt;text-align: left;">Name : ${detail.customerName}</p>
          </td>
          <td style="width:79pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;padding-top: 2pt;">
            <p class="s4" style="padding-top: 1pt;padding-left: 1pt;text-indent: 0pt;text-align: left;">Invoice No.</p>
            <p class="s4" style="padding-top: 3pt;padding-left: 1pt;text-indent: 0pt;text-align: left;">Date</p>
          </td>
          <td style="width:49pt;border-top-style:solid;border-top-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;padding-top: 2pt;">
            <p class="s4" style="padding-top: 1pt;padding-left: 1pt;text-indent: 0pt;text-align: left;">: ${detail.billNo}</p>
            <p class="s4" style="padding-top: 3pt;padding-left: 1pt;text-indent: 0pt;text-align: left;">: ${detail.date}</p>
          </td>
          <td style="width:31pt;border-top-style:solid;border-top-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
          </td>
        </tr>
        <tr style="height:11pt">
          <td style="width:28pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s4" style="text-indent: 0pt;line-height: 9pt;text-align: center;">Sr No</p>
          </td>
          <td style="width:300pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="4">
            <p class="s4" style="text-indent: 0pt;line-height: 9pt;text-align: center;">Product Name</p>
          </td>
          <td style="width:80pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s4" style="text-indent: 0pt;line-height: 9pt;text-align: center;">Qty</p>
          </td>
          <td style="width:79pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s4" style="text-indent: 0pt;line-height: 9pt;text-align: center;">Rate</p>
          </td>
          <td style="width:80pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="2">
            <p class="s4" style="padding-left: 25pt;text-indent: 0pt;line-height: 9pt;text-align: left;">Amount</p>
          </td>
        </tr>
        ${detail.detail?.map((item, index) => (
          `<tr style="height:12pt">
            <td style="width:28pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
              <p class="s2" style="text-indent: 0pt;text-align: center;">
                ${(index + 1)}
              </p>
            </td>
            <td style="width:300pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt"colspan="4">
              <p class="s2" style="padding-left: 1pt;text-indent: 0pt;text-align: left;">
                ${item.item?.name}
              </p>
            </td>
            <td style="width:80pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
              <p class="s2" style="text-indent: 0pt;text-align: center;">
                ${item.qty}
              </p>
            </td>
            <td style="width:79pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
              <p class="s2" style="padding-right: 1pt;text-indent: 0pt;text-align: right;">
                ${item.rate}
              </p>
            </td>
            <td style="width:80pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="2">
              <p class="s2" style="padding-left: 44pt;text-indent: 0pt;text-align: left;">
                ${item.total}
              </p>
            </td>
          </tr>`
        ))}
        <tr style="height:11pt">
          <td style="width:28pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
          </td>
          <td style="width:300pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="4">
            <p class="s2" style="padding-right: 3pt;text-indent: 0pt;line-height: 9pt;text-align: right;">Total :</p>
          </td>
          <td style="width:80pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s2" style="text-indent: 0pt;line-height: 9pt;text-align: center;">${detail.totalQty}</p>
          </td>
          <td style="width:159pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="3">
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
          </td>
        </tr>
        <tr style="height:12pt">
          <td style="width:73pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt" rowspan="2">
            <p class="s4" style="padding-top: 6pt;text-indent: 0pt;line-height: 10pt;text-align: center;">
              Prev.Balance:
            </p>
          </td>
          <td style="width:17pt;border-top-style:solid;border-top-width:1pt" rowspan="2">
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
          </td>
          <td style="width:69pt;border-top-style:solid;border-top-width:1pt" rowspan="2">
            <p class="s4" style="padding-top: 6pt;text-indent: 0pt;line-height: 10pt;text-align: center;">This
              Bill Amount:
            </p>
          </td>
          <td style="width:17pt;border-top-style:solid;border-top-width:1pt" rowspan="2">
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
          </td>
          <td style="width:152pt;border-top-style:solid;border-top-width:1pt" rowspan="2">
            <p class="s4" style="padding-top: 6pt;text-indent: 0pt;line-height: 10pt;text-align: center;">Net
              Bill Amount
            </p>
          </td>
          <td style="width:80pt;border-top-style:solid;border-top-width:1pt;border-right-style:solid;border-right-width:1pt" rowspan="2">
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
          </td>
          <td style="width:159pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="3">
            <table>
              <tr>
                <td style="width:159pt;">
                  <p class="s5" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">
                    Sub Total
                  </p>
                </td>
                <td>
                  <p class="s5" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">
                    ${detail.grandTotal}
                  </p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr style="height:6pt">
          <td style="width:159pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt"colspan="3" rowspan="2">
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
          </td>
        </tr>
        <tr style="height:16pt">
          <td style="width:73pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt">
            <p class="s4" style="text-indent: 0pt;line-height: 9pt;text-align: center;">
              0
            </p>
          </td>
          <td style="width:17pt;border-bottom-style:solid;border-bottom-width:1pt">
            <p class="s4" style="text-indent: 0pt;line-height: 9pt;text-align: center;">
              +
            </p>
          </td>
          <td style="width:69pt;border-bottom-style:solid;border-bottom-width:1pt">
            <p class="s4" style="text-indent: 0pt;line-height: 9pt;text-align: center;">
              ${detail.grandTotal}
            </p>
          </td>
          <td style="width:17pt;border-bottom-style:solid;border-bottom-width:1pt">
            <p class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 9pt;text-align: center;">
              =
            </p>
          </td>
          <td style="width:152pt;border-bottom-style:solid;border-bottom-width:1pt">
            <p class="s4" style="text-indent: 0pt;line-height: 9pt;text-align: center;">
              ${detail.grandTotal}
            </p>
          </td>
          <td style="width:80pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
          </td>
        </tr>
        <tr style="height:12pt">
          <td style="width:408pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="6">
            <p class="s6" style="padding-top: 1pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">
              Bill Amount
              <span class="s7">: ${detail.totalInWord} only</span>
            </p>
          </td>
          <td style="width:159pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="3">
            <table>
              <tr>
                <td style="width:159pt;">
                  <p class="s5" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">
                    Grand Total
                  </p>
                </td>
                <td>
                  <p class="s5" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">
                    ${detail.grandTotal}
                  </p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr style="height:38pt">
          <td style="width:567pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="9">
            <p class="s8" style="padding-top: 2pt;padding-left: 493pt;text-indent: 0pt;text-align: right;">
              For,
                  KANAIYA PLASTIC
            </p>
            <p style="padding-top: 6pt;text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s7" style="padding-left: 497pt;text-indent: 0pt;line-height: 8pt;text-align: right;">
              (Authorised Signatory)
            </p>
          </td>
        </tr>
    </table>
  </body>
</html>
`
}
