import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useNavigate } from 'react-router';

const Report = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* <Box className="card"> */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Box 
              sx={{display: 'grid', gridTemplateColumns: '1fr 70px', backgroundColor: '#364865', color: 'white', borderRadius: '10px', p: '24px 16px', cursor: 'pointer' }} 
              onClick={() => navigate('/reports/bill')}
            >
              <Box>
                <Typography sx={{ fontSize: '1.75rem' }} variant="h2">
                  Bill
                </Typography>
              </Box>
              <Box sx={{ textAlign: 'end' }} >
                <i className="fa fa-file-invoice" style={{ fontSize: '28px'}}></i>
              </Box>
            </Box>
          </Grid>
        </Grid>
      {/* </Box> */}
    </>
  );
};

export default Report;
