import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { showToast } from "../../../utils/helper";
import { startLoading, stopLoading } from "../../../redux/loader";
import {
  saveCompany,
  updateCompany,
  getCompanyById
} from "../../../service/company";

export const useAddEditCompany = (tag) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { id } = useParams();
    const loggedInUser = useSelector((state) => state.loggedInUser);

    const { control, getValues, watch, setValue, handleSubmit } = useForm({
        defaultValues: {
            name: '',
            email: '',
            address: '',
            phoneNumber: '',
            billPrefix: ''
        },
        mode: "onBlur",
    });

    const onSubmit = async (data) => {
        try {
            dispatch(startLoading());
            let payload = {
                ...data
            };
            if(isEditMode) {
                payload = {
                    ...payload,
                    updatedBy: loggedInUser.id
                }
            } else {
                payload = {
                    ...payload,
                    createdBy: loggedInUser.id
                }
            }
            const response = isEditMode ? await updateCompany(id, payload) : await saveCompany(payload);
            if (response.success) {
                showToast(response?.message || response?.messageCode, true);
                navigate("/company");
              } else {
                showToast(response?.message || response?.messageCode, false);
              }
        } catch(error) {
            showToast(error?.message, false);
        } finally {
            dispatch(stopLoading());
        }
    }

    const fetchEditCompanyData = useCallback(async () => {
        try {
            if (id) {
                dispatch(startLoading());
                const response = await getCompanyById(id);
                if (response?.statusCode === 200) {
                    setValue("name", response.data.name);
                    setValue("phoneNumber", response.data.phoneNumber);
                    setValue("address", response.data.address);
                    setValue("email", response.data.email);
                    setValue("billPrefix", response.data.billPrefix);
                } else {
                    showToast(response?.message, false);
                }
            }
        } catch(error) {
            showToast(error?.message, false);
        } finally {
          dispatch(stopLoading());
        }
    }, [id, dispatch, setValue])

    const cancelHandler = () => {
        navigate("/company");
    };

    const isEditMode = useMemo(() => {
        return id && typeof parseInt(id) === 'number' ? true : false
        // eslint-disable-next-line
    }, [id]);

    const isGst = useMemo(() => {
        return getValues('isGst');
        // eslint-disable-next-line
    }, [watch('isGst')]);

    useEffect(() => {
        if(id) {
            fetchEditCompanyData();
        }
        // eslint-disable-next-line
    }, [id]);

    return {
        isGst,
        control,
        isEditMode,
        onSubmit,
        handleSubmit,
        cancelHandler
    }
}