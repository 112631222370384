import React from "react";
import { Controller } from "react-hook-form";

import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from "@mui/material/Typography";

import useAddEditPayment from "./hook/addEditPayment.hooks";

const AddEditPayment = ({ tag }) => {
    const {
        control,
        billList,
        billAmount,
        paidAmount,
        isSubmitting,
        customerList,
        paymentTypeList,
        onSubmit,
        getValues,
        handleSubmit,
        cancelHandler
    } = useAddEditPayment();
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Card>
                    <CardContent>
                        <FormGroup className="form-field">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={8}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Controller
                                                name="date"
                                                control={control}
                                                render={({
                                                    field: { onBlur, onChange, value },
                                                    fieldState: { error }
                                                }) => (
                                                    <FormControl size="small" fullWidth>
                                                        <TextField
                                                            type="date"
                                                            label="Date"
                                                            size="small"
                                                            name="date"
                                                            value={value}
                                                            onChange={(onChange)}
                                                            onBlur={onBlur}
                                                            error={!!error}
                                                            helperText={error?.message ? error.message : ""}
                                                        />
                                                    </FormControl>
                                                )}
                                                rules={{
                                                    required: 'Select Date'
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Controller
                                                name="customerID"
                                                control={control}
                                                render={({
                                                    field: { onBlur, onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <Autocomplete
                                                        freeSolo
                                                        size="small"
                                                        id="customerID"
                                                        options={customerList || []}
                                                        getOptionLabel={(option) => option.name ? option.name : ''}
                                                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                        value={customerList?.find((option) => option.id === value) ?? ''}
                                                        onBlur={onBlur}
                                                        onChange={(_event, value) => {
                                                            if (value) {
                                                                onChange(value?.id)
                                                            } else {
                                                                onChange(null);
                                                            }
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Select Customer"
                                                                error={!!error}
                                                                helperText={error?.message}
                                                            />
                                                        )}
                                                    />
                                                )}
                                                rules={{
                                                    required: 'Select Customer'
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Controller
                                                name="paymentTypeID"
                                                control={control}
                                                render={({
                                                    field: { onBlur, onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <FormControl fullWidth size="small">
                                                        <InputLabel size="small" id="payment">Payment Type</InputLabel>
                                                        <Select
                                                            size="small"
                                                            labelId="payment"
                                                            id="payment-select"
                                                            value={value}
                                                            label="Payment Type"
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                        >
                                                            {paymentTypeList?.map((paymentType, index) => (
                                                                <MenuItem style={{ textTransform: "capitalize" }} key={`payment_type_${index}`} value={paymentType.id} >
                                                                    {paymentType.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {error &&
                                                            <FormHelperText>{error.message}</FormHelperText>
                                                        }
                                                    </FormControl>
                                                )}
                                                rules={{
                                                    required: 'Select Payment Type'
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Controller
                                                name="amount"
                                                control={control}
                                                render={({
                                                    field: { onBlur, onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <FormControl size="small" fullWidth>
                                                        <TextField
                                                            size="small"
                                                            name="amount"
                                                            label="Amount"
                                                            value={value}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            error={!!error}
                                                            helperText={error?.message}
                                                        />
                                                    </FormControl>
                                                )}
                                                rules={{
                                                    required: "Required Amount",
                                                    pattern: {
                                                        value: /^[0-9]/,
                                                        message: "Enter only digit",
                                                    },
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Controller
                                                name="remarks"
                                                control={control}
                                                render={({
                                                    field: { onBlur, onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <FormControl size="small" fullWidth>
                                                        <TextField
                                                            size="small"
                                                            name="remarks"
                                                            className="text-center"
                                                            label="Remarks"
                                                            value={value}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            multiline
                                                            rows={3}
                                                        />
                                                    </FormControl>
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <InputLabel>Bill List:-</InputLabel>
                                    {getValues("customerID") && billList.length ?
                                        <>
                                            <List>
                                                {billList.map((bill, index) => (
                                                    <ListItem key={`bill_${index}`} sx={{ justifyContent: 'space-between'}}>
                                                        <Typography variant="subtitle2">
                                                            {bill?.billNo}
                                                        </Typography>
                                                        <Typography variant="subtitle2">
                                                            {bill?.grandTotal}/-
                                                        </Typography>
                                                    </ListItem>
                                                ))}
                                                <ListItem sx={{ justifyContent: 'space-between', borderTop: '1px dotted black'}}>
                                                    <Typography variant="subtitle2">
                                                        Bill Amount
                                                    </Typography>
                                                    <Typography variant="subtitle2">
                                                        {billAmount}/-
                                                    </Typography>
                                                </ListItem>
                                                <ListItem sx={{ justifyContent: 'space-between', borderTop: '1px dotted black'}}>
                                                    <Typography variant="subtitle2">
                                                        Paid Amount
                                                    </Typography>
                                                    <Typography variant="subtitle2">
                                                        - {paidAmount}/-
                                                    </Typography>
                                                </ListItem>
                                                <ListItem sx={{ justifyContent: 'space-between', borderTop: '1px dotted black'}}>
                                                    <Typography variant="subtitle1" fontSize={22} fontWeight={700}>Pending Amount</Typography> 
                                                    <Typography variant="subtitle1" fontSize={22} fontWeight={700}>{(billAmount - paidAmount)}/-</Typography>
                                                </ListItem>
                                            </List>
                                        </>
                                    : null}
                                </Grid>
                            </Grid>
                        </FormGroup>
                    </CardContent>
                    <Grid container spacing={2} sx={{ marginTop: '6px', marginLeft: '16px', marginBottom: '16px' }}>
                        <Grid item xs={12} sm={3}>
                            <Button disabled={isSubmitting} type="submit" className="btn btn-tertiary">
                                {tag === "add" ? "Save" : "Update"}
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Button sx={{ marginLeft: '12px' }} className="btn btn-cancel" onClick={cancelHandler}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Card>
            </form>
        </>
    )
}

export default AddEditPayment;