import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: []
};

const ReminderSlice = createSlice({
    name: 'reminder',
    initialState: initialState,
    reducers: {
        storeReminder(state, action) {
            return { 
                data: action.payload
            }
        },
        removeReminder(state, action) {
            return {
                data: state.data.filter((row) => row.id !== action.payload.id)
            }
        },
        changeReminderStatus(state, action) {
            const updatedState = state.data.map((row) =>
              row.id === action.payload.id
                ? { ...row, isReminder: action.payload.status }
                : { ...row }
            );
            return { data: updatedState };
        },
    }
});

export const ReminderActions = ReminderSlice.actions;
export default ReminderSlice.reducer;