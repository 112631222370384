import React from "react";
import { Controller } from "react-hook-form";
import {
  FiPlusCircle,
  FiPrinter,
  FiSave,
  FiXCircle,
  FiMinusCircle,
} from "react-icons/fi";

import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { styled } from "@mui/material/styles";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

import ViewDetail from "../../components/ViewDetailModal";

import { useAddEditCreateBill } from "./hook/useAddEditCreateBill";
import moment from "moment";

const StyledTableCell = styled(TableCell)({
  padding: 0,
  margin: 0,
});

const AddEditBill = ({ tag }) => {
  const {
    items,
    fields,
    control,
    customers,
    isPrintBtn,
    isSubmitting,
    selectedCompany,
    isSaveModalOpen,
    isViewDetailOpen,
    addRow,
    onSubmit,
    navigate,
    removeRow,
    printHandler,
    handleSubmit,
    handleItemSelect,
    setIsSaveModalOpen,
    calculateGrandTotal,
    handleCalculateTotal,
    toggleViewDetailOpen,
  } = useAddEditCreateBill(tag);

  return (
    <>
      <form>
        {/* <Box className="card"></Box> */}
        <Card>
          <CardContent>
            <Grid container gap={2}>
              <Grid item xs={12} sm={5.9}>
                <Controller
                  name="billNo"
                  control={control}
                  render={({ field: { value } }) => (
                    <FormControl size="small" fullWidth>
                      <TextField
                        disabled
                        label="Bill No"
                        size="small"
                        name="billNo"
                        value={value}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={5.9}>
                <Controller
                  name="date"
                  control={control}
                  render={({
                    field: { onBlur, onChange, value },
                    fieldState: { error }
                  }) => (
                    <FormControl size="small" fullWidth>
                      <TextField
                        type="date"
                        label="Date"
                        size="small"
                        name="date"
                        value={value}
                        onChange={(onChange)}
                        onBlur={onBlur}
                        error={!!error}
                        helperText={error?.message ? error.message : ""}
                        inputProps={{
                          max: moment(new Date()).format('yyyy-MM-DD')
                        }}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={5.9}>
                <Controller
                  name="customerID"
                  control={control}
                  render={({
                    field: { value, onBlur, onChange },
                    fieldState: { error }
                  }) => (
                    <Autocomplete
                      freeSolo
                      size="small"
                      id="customerID"
                      options={customers || []}
                      getOptionLabel={(option) => option.name ? option.name : ''}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      value={customers?.find((option) => option.id === value) ?? ''}
                      onBlur={onBlur}
                      onChange={(_event, value) => {
                        if(value) {
                          onChange(value?.id)
                        } else {
                          onChange(null);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Customer"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid> 
              <Grid item xs={12} sm={5.9}>
                <Controller
                  name="companyID"
                  control={control}
                  render={() => (
                    <FormControl size="small" fullWidth>
                      <TextField
                        disabled
                        label="Company"
                        size="small"
                        name="companyID"
                        value={selectedCompany}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <br/>
        <Card>
          <CardContent>
            <TableContainer className="table-wrapper">
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell width={"3%"}></StyledTableCell>
                    <StyledTableCell width={"2%"}>Sl.</StyledTableCell>
                    <StyledTableCell width={"30%"}>Item Name</StyledTableCell>
                    <StyledTableCell width={"10%"}>Quantity</StyledTableCell>
                    <StyledTableCell width={"15%"}>Rate</StyledTableCell>
                    <StyledTableCell width={"10%"}>Discount</StyledTableCell>
                    <StyledTableCell width={"15%"}>Total</StyledTableCell>
                    <StyledTableCell width={"2%"}></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fields?.map((field, index) => (
                    <TableRow key={field.id}>
                      <StyledTableCell>
                        {fields.length === index + 1 && (
                          <Button
                            type="button"
                            onClick={() => {
                              addRow();
                              handleCalculateTotal(index);
                            }}
                          >
                            <FiPlusCircle /> &nbsp;
                          </Button>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {index + 1}
                      </StyledTableCell>
                      <TableCell>
                        <Controller
                          name={`detail.${index}.itemID`}
                          control={control}
                          render={({
                            field: { onBlur, onChange, value },
                            fieldState: { error },
                          }) => (
                            <Autocomplete
                              size="small"
                              disablePortal
                              id="item"
                              options={items || []}
                              getOptionLabel={(option) => option.name ? option.name : ''}
                              isOptionEqualToValue={(option, value) => option?.id === value}
                              value={items?.find((option) => option.id === value) || ''}
                              onBlur={onBlur}
                              onChange={(_event, value) => {
                                if(value) {
                                  onChange(value?.id)
                                  handleItemSelect(value, index);
                                } else {
                                  onChange('');
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Item"
                                  error={!!error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          )}
                          rules={{
                            required: "Item Required",
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Controller
                          name={`detail.${index}.qty`}
                          control={control}
                          render={({
                            field: { onBlur, onChange, value },
                            fieldState: { error },
                          }) => (
                            <FormControl size="small" fullWidth>
                              <TextField
                                size="small"
                                name="quantity"
                                className="text-center"
                                value={value}
                                onChange={(e) => [onChange(e), handleCalculateTotal(index)]}
                                onBlur={onBlur}
                                error={!!error}
                                helperText={error?.message}
                              />
                            </FormControl>
                          )}
                          rules={{
                            required: "Required Qty",
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Controller
                          name={`detail.${index}.rate`}
                          control={control}
                          render={({
                            field: { onBlur, onChange, value },
                            fieldState: { error },
                          }) => (
                            <FormControl size="small" fullWidth>
                              <TextField
                                size="small"
                                name="rate"
                                className="text-center"
                                value={value}
                                onChange={(e) => [onChange(e), handleCalculateTotal(index)]}
                                onBlur={onBlur}
                                error={!!error}
                                helperText={error?.message}
                              />
                            </FormControl>
                          )}
                          rules={{
                            required: "Required Rate",
                            pattern: {
                              value: /^[0-9]/,
                              message: "Enter only digit",
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Controller
                          name={`detail.${index}.discount`}
                          control={control}
                          render={({
                            field: { onBlur, onChange, value },
                            fieldState: { error },
                          }) => (
                            <FormControl size="small" fullWidth>
                              <TextField
                                size="small"
                                name="discount"
                                className="text-center"
                                value={value}
                                onChange={(e) => {
                                  if(e.target.value <= 100) {
                                    onChange(e);
                                    handleCalculateTotal(index);
                                  }
                                }}
                                onBlur={onBlur}
                                error={!!error}
                                helperText={error?.message}
                              />
                            </FormControl>
                          )}
                          rules={{
                            required: "Required Discount",
                            min: {
                              value: 0,
                              message: "",
                            },
                            max: {
                              value: 100,
                              message: "",
                            },
                            pattern: {
                              value: /^[0-9]/,
                              message: "Enter only digit",
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Controller
                          name={`detail.${index}.total`}
                          control={control}
                          render={({ field: { value } }) => (
                            <FormControl size="small" variant="standard" fullWidth>
                              <TextField
                                size="small"
                                name="total"
                                value={value}
                                disabled
                              />
                            </FormControl>
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        {fields.length !== 1 && (
                          <Button
                            type="button"
                            onClick={() => {
                              removeRow(index);
                            }}
                          >
                            <FiMinusCircle /> &nbsp;
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={5} sx={{ padding: 0, margin: 0}}></TableCell>
                    <TableCell sx={{ textAlign: 'end', padding: 0, margin: 0}}>Packing Forward Charges: </TableCell>
                    <TableCell sx={{ textAlign: 'end', padding: 1, margin: 0}}>
                      <Controller
                        name="packingForwardingCharges"
                        control={control}
                        render={({
                          field: { onBlur, onChange, value },
                          fieldState: { error },
                        }) => (
                          <FormControl size="small" fullWidth>
                            <TextField
                              size="small"
                              name="packingForwardingCharges"
                              value={value || ''}
                              onChange={(e) => {
                                onChange(e);
                                calculateGrandTotal();
                              }}
                              onBlur={onBlur}
                              error={!!error}
                              helperText={error?.message}
                            />
                          </FormControl>
                        )}
                        rules={{
                          required: "Required Packing Charges",
                          pattern: {
                            value: /^[0-9]/,
                            message: "Enter only digit",
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={5} sx={{ padding: 0, margin: 0}}></TableCell>
                    <TableCell sx={{ textAlign: 'end', padding: 0, margin: 0}}>Grand Total: </TableCell>
                    <TableCell sx={{ textAlign: 'end', padding: 1, margin: 0}}>
                      <Controller
                        name="grandTotal"
                        control={control}
                        render={({ field: { value } }) => (
                          <FormControl
                            size="small"
                            variant="standard">
                            <TextField
                              disabled
                              size="small"
                              name="grandTotal"
                              value={value}
                            />
                          </FormControl>
                        )}
                      />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
        <br/>
        {/* footer button */}
        <Grid container spacing={3} sx={{ marginTop: "6px" }}>
          <Grid item xs={1.5}>
            <Button
              // type="submit"
              className="btn btn-tertiary"
              onClick={handleSubmit(onSubmit)}
              // onClick={(e) => {
              //   e.stopPropagation();
              //   setIsPrintBtn(false);
              // }}
              disabled={isSubmitting}
            >
              <FiSave /> &nbsp; <p>Save</p>
            </Button>
          </Grid>
          <Grid item xs={1.5}>
            <Button
              // type="submit"
              className="btn btn-tertiary"
              onClick={handleSubmit(printHandler)}
              // onClick={(e) => {
              //   e.stopPropagation();
              //   // setIsPrintBtn(true);
              //   handleSubmit(printHandler);
              // }}
              disabled={isSubmitting}
            >
              <FiPrinter /> &nbsp; <p>Print</p>
            </Button>
          </Grid>
          <Grid item xs={1.5}>
            <Button
              onClick={() => navigate("/bill")}
              className="btn btn-tertiary"
            >
              <FiXCircle /> &nbsp; <p>Close</p>
            </Button>
          </Grid>
        </Grid>
        {isViewDetailOpen && (
          <ViewDetail
            open={isViewDetailOpen}
            handleClose={toggleViewDetailOpen}
            detail={{
              // customer: `${getValues('Phone')} (${getValues('customerID')['label']})`,
              // service: getValues('detail')[0].serviceID['label'],
              // manager: getValues('managerName'),
              // staff: getValues('staffID')['label'],
              // payment: typeof getValues('paymentID') === 'object' ? getValues('paymentID')['label'] : paymentTypeOptions.find((paymentType) => paymentType.value === parseInt(getValues('paymentID')))?.label,
              // rate: getValues('detail')[0].rate,
            }}
            handleOk={isPrintBtn ? handleSubmit(printHandler) : handleSubmit(onSubmit)}
            okTitle={isPrintBtn ? 'Print' : 'Save'}
          />
        )}
      </form>

      <>
        <Modal
          disableEscapeKeyDown
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={isSaveModalOpen}
          closeAfterTransition
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={isSaveModalOpen}>
            <Box className="modal-wrapper modal-bg">
              <Typography
                variant="h6"
                component="h6"
                className="text-black modal-title"
              >
                Save Bill
              </Typography>
              <Box className="modal-body">
                <Box className="confirmation-text">
                  <Typography paragraph>
                    Do you want to save the changes?
                  </Typography>
                </Box>
              </Box>
              <Box className="modal-footer">
                <Grid container spacing={3}>
                  <Grid item md={4} xs={12}>
                    <Button className="btn btn-tertiary">Save</Button>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Button
                      className="btn btn-cancel"
                      onClick={() => {}}
                    >
                      Don't Save
                    </Button>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Button
                      className="btn btn-cancel"
                      onClick={() => setIsSaveModalOpen(false)}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </>
    </>
  );
};

export default AddEditBill;
