import React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { FiEdit3, FiTrash2 } from "react-icons/fi";
import TopBar from "../../components/TopBar";
import ConfirmationModal from "../../components/ConfirmationModal";
import usePaymentHook from "./hook/payment.hooks";

const Payment = () => {
    const {
        page,
        count,
        rights,
        isAdmin,
        visibleRows,
        isDeleteModalOpen,
        deleteHandler,
        handleChangePage,
        searchPaymentHandler,
        setIsDeleteModalOpen,
        deleteBtnClickHandler,
    } = usePaymentHook();
    const navigate = useNavigate();
    let index = page * 10;
    return (
        <>
            <TopBar
                btnTitle="Add Payment"
                inputName="payment"
                navigatePath="/add-payment"
                callAPI={searchPaymentHandler}
                addPermission={rights.add}
            />
            {/* customer listing */}
            <Box className="card">
                <TableContainer className="table-wrapper">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>No</TableCell>
                                <TableCell>Date</TableCell>
                                {isAdmin &&
                                    <TableCell>Company</TableCell>
                                }
                                <TableCell>Customer</TableCell>
                                <TableCell>Payment Type</TableCell>
                                <TableCell>Amount</TableCell>
                                {(rights.edit || rights.delete) && (
                                    <TableCell>Action</TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {visibleRows.length ? (
                                visibleRows.map((row) => {
                                    return (
                                        <TableRow key={"customer_" + row.id}>
                                            <TableCell align="left">{(index += 1)}</TableCell>
                                            <TableCell align="left">{row.date}</TableCell>
                                            {isAdmin &&
                                                <TableCell align="left">{row?.px_company?.name}</TableCell>
                                            }
                                            <TableCell align="left">
                                                {row.px_customer?.name}
                                                {isAdmin &&
                                                    `(${row.px_customer?.phoneNumber})`
                                                }
                                            </TableCell>
                                            <TableCell align="left">{row.px_payment_type?.name}</TableCell>
                                            <TableCell align="left">{row.amount}/-</TableCell>
                                            {(rights.edit || rights.delete) && (
                                                <TableCell align="left">
                                                    <Box className="table-action-btn">
                                                        {rights.edit && (
                                                            <Button
                                                                className="btn btn-primary"
                                                                onClick={() =>
                                                                    navigate(`/edit-payment/${row.id}`)
                                                                }
                                                            >
                                                                <FiEdit3 size={15} />
                                                            </Button>
                                                        )}
                                                        {rights.delete && (
                                                            <Button
                                                                className="btn btn-primary"
                                                                onClick={deleteBtnClickHandler.bind(
                                                                    null,
                                                                    row.id
                                                                )}
                                                            >
                                                                <FiTrash2 size={15} />
                                                            </Button>
                                                        )}
                                                    </Box>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell sx={{ textAlign: "center" }} colSpan={6}>
                                        No customers Found
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10]}
                    component="div"
                    count={count}
                    rowsPerPage={10}
                    page={page}
                    onPageChange={handleChangePage}
                />
            </Box>

            {isDeleteModalOpen && (
                <ConfirmationModal
                    isDeleteModalOpen={isDeleteModalOpen}
                    setIsDeleteModalOpen={setIsDeleteModalOpen}
                    title="payment"
                    deleteHandler={deleteHandler}
                />
            )}
        </>
    );
};

export default Payment;