import React from "react";
import { Controller } from "react-hook-form";

import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import moment from "moment";

const AddEditReminder = ({
    open,
    control,
    onSubmit,
    closeModal,
    customerList,
    handleSubmit,
}) => {
    return (
        <>
            <Modal
                disableEscapeKeyDown
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={closeModal}
                closeAfterTransition
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box className="modal-wrapper modal-lg modal-bg">
                            <Typography
                                variant="h6"
                                component="h6"
                                className="text-black modal-title"
                            >
                                Add/Edit Reminder
                            </Typography>
                            <Box className="modal-body">
                                <FormGroup className="form-field">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={3}>
                                            <Controller
                                                name="nextDate"
                                                control={control}
                                                render={({
                                                    field: { onBlur, onChange, value },
                                                    fieldState: { error }
                                                }) => (
                                                    <FormControl size="small" fullWidth>
                                                        <TextField
                                                            type="date"
                                                            label="Next Date"
                                                            size="small"
                                                            name="date"
                                                            value={value || ''}
                                                            onChange={(onChange)}
                                                            onBlur={onBlur}
                                                            error={!!error}
                                                            helperText={error?.message ? error.message : ""}
                                                            inputProps={{
                                                                min: moment(new Date()).add(1, 'day').format('yyyy-MM-DD')
                                                            }}
                                                        />
                                                    </FormControl>
                                                )}
                                                rules={{
                                                    required: 'Select Next Reminder Date'
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Controller
                                                name="customerID"
                                                control={control}
                                                render={({
                                                    field: { onBlur, onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <Autocomplete
                                                        freeSolo
                                                        size="small"
                                                        id="customerID"
                                                        options={customerList || []}
                                                        getOptionLabel={(option) => option.name ? option.name : ''}
                                                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                        value={customerList?.find((option) => option.id === value) ?? ''}
                                                        onBlur={onBlur}
                                                        onChange={(_event, value) => {
                                                            if (value) {
                                                                onChange(value?.id)
                                                            } else {
                                                                onChange(null);
                                                            }
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Select Customer"
                                                                error={!!error}
                                                                helperText={error?.message}
                                                            />
                                                        )}
                                                    />
                                                )}
                                                rules={{
                                                    required: 'Select Customer'
                                                }}
                                            />
                                        </Grid> 
                                    </Grid>
                                </FormGroup>
                            </Box>
                            <Box className="modal-footer">
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={3}>
                                        <Button fullWidth type="submit" className="btn btn-tertiary">
                                            Submit
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Button fullWidth type="button" className="btn btn-cancel" onClick={closeModal}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </form>
                </Fade>
            </Modal>
        </>
    )
}

export default AddEditReminder;