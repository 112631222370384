import {
    SETTING_API
} from "../utils/constant";

import { post, put, remove, get } from "./webRequest";

export const listSetting = async (body) => {
    return await post(`${SETTING_API}/list`, body);
}

export const getSettingById = async (id) => {
    return await get(`${SETTING_API}/${id}`);
}

export const getSettingByPayload = async (body) => {
    return await post(`${SETTING_API}/get`, body);
}

export const saveSetting = async (body) => {
    return await post(`${SETTING_API}`, body);
}

export const updateSetting = async (id, body) => {
    return await put(`${SETTING_API}/${id}`, body);
}

export const deleteSetting = async (id) => {
    return await remove(`${SETTING_API}/${id}`);
}