import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { socket } from "../../service/webRequest";

export const useWhatsAppWebHooks = () => {
    const loggedInUser = useSelector((state) => state.loggedInUser);
    const [ qrCode, setQrCode ] = useState(null);
    const [ sessionCreated, setSessionCreated ] = useState(false);

    const handleLogout = () => {
        socket.emit(`logout_${loggedInUser.phoneNumber}`, loggedInUser.phoneNumber);
    }

    useEffect(() => {
        if(loggedInUser) {
            socket.emit('createSession', {phoneNumber: loggedInUser.phoneNumber})
            socket.emit(`isLogin_${loggedInUser.phoneNumber}`);
        socket.on(`qr_${loggedInUser.phoneNumber}`, (qr) => {
            setQrCode(qr);
        });
        
        socket.on(`ready_${loggedInUser.phoneNumber}`, (msg) => {
            setSessionCreated(true);
        });

        socket.on(`reload_${loggedInUser.phoneNumber}`, () => {
            socket.emit('createSession', {phoneNumber: loggedInUser.phoneNumber});
            setSessionCreated(false);
            setQrCode(null);
            // window.location.reload();
        });
    }
        return () => {
            socket.off(`qr_${loggedInUser.phoneNumber}`);
            socket.off(`ready_${loggedInUser.phoneNumber}`);
            socket.off(`reload_${loggedInUser.phoneNumber}`);
        }
    }, [loggedInUser]);

    return {
        qrCode,
        sessionCreated,
        handleLogout
    }
}