import {
    PAYMENT_API
} from "../utils/constant";
import { get, post, remove, put } from "./webRequest";

export const getPaymentList = async (body) => {
    return await post(`${PAYMENT_API}/list`, body);

};

export const createPayment = async (body) => {
    return await post(PAYMENT_API, body);
};

export const getPaymentById = async (id) => {
    return await get(`${PAYMENT_API}/${id}`);
};

export const updatePayment = async (payload, id) => {
    return await put(`${PAYMENT_API}/${id}`, payload);
};

export const deletePayment = async (id) => {
    return await remove(`${PAYMENT_API}/${id}`);
};
