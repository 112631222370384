import {
    REMINDER_API
} from "../utils/constant";

import { post, put, remove, get } from "./webRequest";

export const listReminder = async (body) => {
    return await post(`${REMINDER_API}/list`, body);
}

export const getReminderById = async (id) => {
    return await get(`${REMINDER_API}/${id}`);
}

export const getReminderByPayload = async (body) => {
    return await post(`${REMINDER_API}/get`, body);
}

export const saveReminder = async (body) => {
    return await post(`${REMINDER_API}`, body);
}

export const updateReminder = async (id, body) => {
    return await put(`${REMINDER_API}/${id}`, body);
}

export const deleteReminder = async (id) => {
    return await remove(`${REMINDER_API}/${id}`);
}