import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";

import { listPayload, showToast } from "../../../utils/helper";
import { startLoading, stopLoading } from "../../../redux/loader";
import {
    getPaymentById,
    createPayment,
    updatePayment,
    getPaymentList
} from "../../../service/payment";
import {
    getBillList
} from "../../../service/bill";
import {
    getCustomerList
} from "../../../service/customer";
import {
    getPaymentTypeList
} from "../../../service/paymentType";


const useAddEditPayment = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { id } = useParams();
    const loggedInUser = useSelector((state) => state.loggedInUser);
    
    const [billList, setBillList] = useState([]);
    const [paymentList, setPaymentList] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [paymentTypeList, setPaymentTypeList] = useState([]);

    const { control, watch, getValues, setValue, handleSubmit, formState: {isSubmitting} } = useForm({
        defaultValues: {
          companyID: loggedInUser?.companyID,
          customerID: "",
          paymentTypeID: "",
          date: moment(new Date()).format('yyyy-MM-DD'),
          amount: "",
          remarks: ""
        },
        mode: "onBlur",
    });

    const onSubmit = async (data) => {
        try {
            dispatch(startLoading());
            const payload = {
                companyID: loggedInUser?.companyID,
                customerID: data.customerID,
                paymentTypeID: data.paymentTypeID,
                date: data.date,
                amount: data.amount,
                remarks: data.remarks
            }
            const resposne = id ? await updatePayment({...payload, updatedBy: loggedInUser.id}, id) : await createPayment({...payload, createdBy: loggedInUser.id});
            if (resposne.statusCode === 200) {
                showToast(resposne.message, true);
                navigate("/payment");
            } else {
                showToast(resposne.message, false);
            }
        } catch (error) {
            showToast(error?.message, false);
        } finally {
            dispatch(stopLoading());
        }
    }

    const searchBill = async () => {
        try {
            dispatch(startLoading());
            const whereCondition = {
                customerID: getValues('customerID'),
                isActive: true,
                isDeleted: false
            };
            const payload = listPayload(0, whereCondition, 1000000);
            const { success, data } = await getBillList(payload);
            if(success) {
                setBillList(data?.rows);
            } else {
                setBillList([]);
            }
        } catch(error) {
            showToast(error?.message, false);
        } finally {
            dispatch(stopLoading());
        }
    };

    const searchPayment = async () => {
        try {
            dispatch(startLoading());
            const whereCondition = {
                customerID: getValues('customerID'),
                isActive: true,
                isDeleted: false
            };
            const payload = listPayload(0, whereCondition, 1000000);
            const { success, data } = await getPaymentList(payload);
            if(success) {
                setPaymentList(data?.rows);
            } else {
                setPaymentList([]);
            }
        } catch(error) {
            showToast(error?.message, false);
        } finally {
            dispatch(stopLoading());
        }
    }

    const billAmount = useMemo(() => {
        if(billList.length) {
            return billList?.reduce((total, item) => (total + item.grandTotal), 0);
        }
        return 0;
    }, [billList]);

    const paidAmount = useMemo(() => {
        if(paymentList.length) {
            return paymentList?.reduce((total, item) => (total + item.amount), 0);
        }
        return 0;
    }, [paymentList]);

    // const pendingAmount = useMemo(() => {
    //     return billAmount - paidAmount;
    // }, [billAmount, paidAmount]);

    useEffect(() => { 
        if(getValues("customerID")) {
            searchBill();
            searchPayment();
        }  
        // eslint-disable-next-line
    }, [watch('customerID')]);

    useEffect(() => {
        if(id) {
            (async () => {
                try {
                    dispatch(startLoading());
                    const response = await getPaymentById(id);
                    if(response.success) {
                        setValue("companyID", response.data.companyID);
                        setValue("customerID", response.data.customerID);
                        setValue("paymentTypeID", response.data.paymentTypeID);
                        setValue("date", moment(new Date(response.data.date)).format('yyyy-MM-DD'));
                        setValue("amount", response.data.amount);
                        setValue("remarks", response.data.remarks);
                        searchBill(response.data.px_bill?.billNo);
                    } else {
                        showToast(response?.message, false);
                    }
                } catch(error) {
                    showToast(error?.message, false);
                } finally {
                    dispatch(stopLoading());
                }
            })();
        }
        // eslint-disable-next-line
    }, [id, dispatch, setValue]);

    const cancelHandler = () => {
        navigate("/payment");
    };

    useEffect(() => {
        (async () => {
        try {
            const body = listPayload(0, { isActive: true }, 1000);
            const [
                responsePayment,
                responseCustomer
            ] = await Promise.all([
                getPaymentTypeList(body),
                getCustomerList(body)
            ]);
            if(responsePayment.statusCode === 200) {
                const payload = responsePayment?.data?.rows;
                setPaymentTypeList(payload);
            } else {
                setPaymentTypeList([]);
            }
            if(responseCustomer.statusCode === 200) {
                const payload = responseCustomer?.data?.rows;
                setCustomerList(payload);
            } else {
                setCustomerList([]);
            }
        } catch(error) {
            showToast(error.message, false);
        }
        })();
        // eslint-disable-next-line
    }, []);

    return {
        control,
        billList,
        paidAmount,
        billAmount,
        isSubmitting,
        customerList,
        paymentTypeList,
        onSubmit,
        getValues,
        searchBill,
        handleSubmit,
        cancelHandler
    }
}

export default useAddEditPayment;