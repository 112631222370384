import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { listPayload, showToast } from "../../../utils/helper";
import { startLoading, stopLoading } from "../../../redux/loader";
import {
    saveItem,
    updateItem,
    getItemById,
} from "../../../service/item";
import { listCompany } from "../../../service/company";

export const useAddEditItemHook = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { id } = useParams();
    const loggedInUser = useSelector((state) => state.loggedInUser);

    const [company, setCompany] = useState([]);

    const { control, setValue, handleSubmit } = useForm({
        defaultValues: {
            name: "",
            rate: "",
            companyID: loggedInUser.px_company.id,
        },
        mode: "onBlur",
    });

    const cancelHandler = () => {
        navigate("/item");
    };

    const onSubmit = async (data) => {
        try {
            dispatch(startLoading());
            let payload = {
                ...data
            }
            if(isEditMode) {
                payload = {
                    ...payload,
                    updatedBy: loggedInUser.id
                }
            } else {
                payload = {
                    ...payload,
                    createdBy: loggedInUser.id
                }
            }
            const response = isEditMode ? await updateItem(id, payload) : await saveItem(payload);
            if (response.success) {
                showToast(response?.message || response?.messageCode, true);
                navigate("/item");
              } else {
                showToast(response?.message || response?.messageCode, false);
              }
        } catch(error) {
            showToast(error?.message, false);
        } finally {
            dispatch(stopLoading());
        }
    }

    const fetchEditItemData = useCallback(async () => {
        try {
            if (id) {
                dispatch(startLoading());
                const response = await getItemById(id);
                if (response?.statusCode === 200) {
                    setValue("companyID", response.data.companyID);
                    setValue("name", response.data.name);
                    setValue("rate", response.data.rate);
                } else {
                    showToast(response?.message, false);
                }
            }
        } catch(error) {
            showToast(error?.message, false);
        } finally {
          dispatch(stopLoading());
        }
    }, [id, dispatch, setValue])

    const isEditMode = useMemo(() => {
        return id && typeof parseInt(id) === 'number' ? true : false
        // eslint-disable-next-line
    }, [id]);

    const isSuperAdmin = useMemo(() => {
        if(loggedInUser && loggedInUser.px_role) {
          return ['super admin'].includes(loggedInUser.px_role.name.toLowerCase());
        }
    }, [loggedInUser]);

    useEffect(() => {
        (async () => {
          try {
            const body = listPayload(0, { isActive: true }, 1000);
            const responseCompany = await listCompany(body);
            if(responseCompany.statusCode === 200) {
              const payload = responseCompany?.data?.rows;
              setCompany(payload);
            } else {
              setCompany([]);
            }
          } catch(error) {
            showToast(error.message, false);
          }
        })();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(id) {
            fetchEditItemData();
        }
        // eslint-disable-next-line
    }, [id]);

    return {
        control,
        company,
        isEditMode,
        isSuperAdmin,
        onSubmit,
        handleSubmit,
        cancelHandler
    }

}