import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { listPayload, rightsAccess, showToast } from "../../../utils/helper";
import { PaymentActions } from "../../../redux/payment";
import { getPaymentList, updatePayment, deletePayment } from "../../../service/payment";
import { startLoading, stopLoading } from "../../../redux/loader";

const usePaymentHook = () => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const loggedInUser = useSelector((state) => state.loggedInUser);
    const paymentData = useSelector((state) => state.payment.data);

    const { accessModules } = loggedInUser;

    const [deleteId, setDeleteId] = useState("");
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    // pagination start
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const visibleRows = useMemo(() => {
        return paymentData;
    }, [paymentData]);

    // pagination end

    const rights = useMemo(() => {
        return rightsAccess(accessModules, pathname);
    }, [accessModules, pathname]);
    
    const isAdmin = useMemo(() => {
        if(loggedInUser && loggedInUser.px_role && ['admin', 'super admin'].includes(loggedInUser.px_role.name.toLowerCase())) {
            return true;
        }
        return false;
    }, [loggedInUser]);

    //  fetch customer logic
    const fetchPaymentData = useCallback(
        async (searchValue = "") => {
            try {
                dispatch(startLoading());
                const payload = { searchText: searchValue };
                const body = listPayload(page, { ...payload });
                const response = await getPaymentList(body);
                if (response?.statusCode === 200) {
                    const payload = response?.data;
                    setCount(payload?.count);
                    dispatch(PaymentActions.storePayment(payload.rows));
                } else if (response?.statusCode === 404) {
                    const payload = [];
                    dispatch(PaymentActions.storePayment(payload));
                }
            } catch (error) {
                showToast(error?.message, false);
            } finally {
                dispatch(stopLoading());
            }
        },
        [dispatch, page]
    );

    const searchPaymentHandler = async (payload) => {
        try {
            fetchPaymentData(payload.searchValue);
        } catch (error) {
            showToast(error.message, false);
        }
    };

    useEffect(() => {
        fetchPaymentData();
    }, [fetchPaymentData]);

    const deleteBtnClickHandler = (id) => {
        setDeleteId(id);
        setIsDeleteModalOpen(true);
    };

    const deleteHandler = async () => {
        try {
            // setIsDeleteModalOpen(false);
            dispatch(startLoading());
            const response = await deletePayment(deleteId);
            if (response?.statusCode === 200) {
                showToast(response?.message, true);
                dispatch(PaymentActions.removePayment({ id: deleteId }));
                setCount((prev) => prev - 1);
            } else {
                showToast(response?.messageCode, false);
            }
        } catch (error) {
            showToast(error?.message, false);
        } finally {
            setIsDeleteModalOpen(false);
            dispatch(stopLoading());
        }
    };

    const changeStatusHandler = async (e, id) => {
        try {
            const payload = {
                isActive: e.target.checked,
                updatedBy: loggedInUser.id,
            };
            const response = await updatePayment(payload, id);

            if (response?.statusCode === 200) {
                showToast(response?.message, true);
                // fetchCustomerData();
                const payload2 = { id, status: payload.isActive };
                dispatch(PaymentActions.changePaymentStatus(payload2));
            } else {
                showToast(response?.message, false);
            }
        } catch (error) {
            showToast(error?.message, false);
        }
    };

    return {
        page,
        count,
        rights,
        isAdmin,
        visibleRows,
        isDeleteModalOpen,
        deleteHandler,
        handleChangePage,
        changeStatusHandler,
        searchPaymentHandler,
        setIsDeleteModalOpen,
        deleteBtnClickHandler,
    };
}

export default usePaymentHook;