import React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Switch from "@mui/material/Switch";
import { FiEdit3, FiTrash2 } from "react-icons/fi";
import TopBar from "../../components/TopBar";
import ConfirmationModal from "../../components/ConfirmationModal";
import { useCompanyHook } from "./hook/useCompany.hook";

const switchStyles = {
    color: "var(--color-black)",
    "&.MuiChecked": {
        color: "green",
    },
    "&.MuiChecked + .MuiSwitchTrack": {
        backgroundColor: "lightgreen",
    },
};

const Company = () => {
    const navigate = useNavigate();
    const {
        rows,
        page,
        count,
        rights,
        isDeleteModalOpen,
        deleteHandler,
        handleChangePage,
        changeStatusHandler,
        setIsDeleteModalOpen,
        searchCompanyHandler,
        deleteBtnClickHandler,
    } = useCompanyHook();
    let index = page * 10;
    return (
        <>
            <TopBar
                btnTitle="Add Company"
                inputName="Company"
                navigatePath="/add-company"
                callAPI={searchCompanyHandler}
                addPermission={rights.add}
            />
            <Box className="card">
                <TableContainer className="table-wrapper">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>No</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Phone</TableCell>
                                <TableCell>Email</TableCell>
                                {rights.edit && <TableCell>Status</TableCell>}
                                {(rights.edit || rights.delete) && (
                                    <TableCell>Action</TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows && rows.length ? rows?.map((row) => (
                                <TableRow key={"company_" + row.id}>
                                    <TableCell align="left">{(index += 1)}</TableCell>
                                    <TableCell align="left">{row.name}</TableCell>
                                    <TableCell align="left">{row.phoneNumber}</TableCell>
                                    <TableCell align="left">{row.email}</TableCell>
                                    {rights.edit && (
                                        <TableCell>
                                            <Switch
                                                style={switchStyles}
                                                checked={row.isActive}
                                                onChange={(e) => changeStatusHandler(e, row.id)}
                                            />
                                        </TableCell>
                                    )}
                                    {(rights.edit || rights.delete) && (
                                        <TableCell align="left">
                                            <Box className="table-action-btn">
                                                {rights.edit && (
                                                    <Button
                                                        className="btn btn-primary"
                                                        onClick={() =>
                                                            navigate(`/edit-company/${row.id}`)
                                                        }
                                                    >
                                                        <FiEdit3 size={15} />
                                                    </Button>
                                                )}
                                                {rights.delete && (
                                                    <Button
                                                        className="btn btn-primary"
                                                        onClick={deleteBtnClickHandler.bind(null, row.id)}
                                                    >
                                                        <FiTrash2 size={15} />
                                                    </Button>
                                                )}
                                            </Box>
                                        </TableCell>
                                    )}
                                </TableRow>
                            )) : (
                                <TableRow>
                                    <TableCell sx={{ textAlign: "center" }} colSpan={6}>
                                        No Company Found
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10]}
                    component="div"
                    count={count}
                    rowsPerPage={10}
                    page={page}
                    onPageChange={handleChangePage}
                />
            </Box>
            {isDeleteModalOpen && (
                <ConfirmationModal
                    isDeleteModalOpen={isDeleteModalOpen}
                    setIsDeleteModalOpen={setIsDeleteModalOpen}
                    title="customer"
                    deleteHandler={deleteHandler}
                />
            )}
        </>
    );
};

export default Company;
