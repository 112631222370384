
import React from "react";
import { Controller } from "react-hook-form";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import { useAddEditCompany } from "./hook/addEditCompany.hook";

const AddEditCompany = () => {
    const {
        control,
        isEditMode,
        onSubmit,
        handleSubmit,
        cancelHandler
    } = useAddEditCompany()
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box className="card">
                    <FormGroup className="form-field">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Controller
                                            name="name"
                                            control={control}
                                            render={({
                                                field: { onBlur, onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <FormControl
                                                    size="small"
                                                    variant="standard"
                                                    className="form-control"
                                                >
                                                    <TextField
                                                        label="Company name"
                                                        size="small"
                                                        name="name"
                                                        value={value}
                                                        onChange={(e) => {
                                                            // if (e.target.value.length < 15) {
                                                            onChange(e.target.value.toUpperCase())
                                                            // }
                                                        }}
                                                        onBlur={onBlur}
                                                        error={!!error}
                                                        helperText={error?.message}
                                                    />
                                                </FormControl>
                                            )}
                                            rules={{
                                                required: "Company name field required",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Controller
                                            name="phoneNumber"
                                            control={control}
                                            render={({
                                                field: { onBlur, onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <FormControl
                                                    size="small"
                                                    variant="standard"
                                                    className="form-control"
                                                >
                                                    <TextField
                                                        type="number"
                                                        label="Phone"
                                                        size="small"
                                                        name="phoneNumber"
                                                        value={value}
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        error={!!error}
                                                        helperText={error?.message}
                                                    />
                                                </FormControl>
                                            )}
                                            rules={{
                                                required: "Phone number is required",
                                                maxLength: {
                                                    value: 10,
                                                    message: "Phone number must be 10 digit",
                                                },
                                                minLength: {
                                                    value: 10,
                                                    message: "Phone number must be 10 digit",
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Controller
                                            name="email"
                                            control={control}
                                            render={({
                                                field: { onBlur, onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <FormControl
                                                    size="small"
                                                    variant="standard"
                                                    className="form-control"
                                                >
                                                    <TextField
                                                        label="Email*"
                                                        size="small"
                                                        name="email"
                                                        value={value}
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        error={!!error}
                                                        helperText={error?.message}
                                                    />
                                                </FormControl>
                                            )}
                                            rules={{
                                                required: "Email is quired",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Controller
                                            name="billPrefix"
                                            control={control}
                                            render={({
                                                field: { onBlur, onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <FormControl
                                                    size="small"
                                                    variant="standard"
                                                    className="form-control"
                                                >
                                                    <TextField
                                                        label="Bill Prefix*"
                                                        size="small"
                                                        name="billPrefix"
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e.target.value.toUpperCase());
                                                        }}
                                                        onBlur={onBlur}
                                                        error={!!error}
                                                        helperText={error?.message}
                                                    />
                                                </FormControl>
                                            )}
                                            rules={{
                                                required: "Bill Prefix is required"
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Controller
                                            name="address"
                                            control={control}
                                            render={({
                                                field: { onBlur, onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <FormControl
                                                    size="small"
                                                    variant="standard"
                                                    className="form-control"
                                                >
                                                    <TextField
                                                        label="Address*"
                                                        size="small"
                                                        name="address"
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e.target.value.toUpperCase());
                                                        }}
                                                        onBlur={onBlur}
                                                        error={!!error}
                                                        helperText={error?.message}
                                                        multiline={true}
                                                        rows={3}
                                                    />
                                                </FormControl>
                                            )}
                                            rules={{
                                                required: "Address is required",
                                            }}
                                        />
                                    </Grid>
                        </Grid>
                    </FormGroup>
                </Box>
                <Grid container spacing={3} sx={{ marginTop: "6px" }}>
                    <Grid item md={1.5}>
                        <Button type="submit" className="btn btn-tertiary">
                            {isEditMode ? "Update" : "Save"}
                        </Button>
                    </Grid>
                    <Grid item md={1.5}>
                        <Button className="btn btn-cancel" onClick={cancelHandler}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

export default AddEditCompany;