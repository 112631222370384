import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { ReminderActions } from "../../../redux/reminder";
import { fetchDashboardDetails } from "../../../service/home";
import { getCustomerList } from "../../../service/customer";
import {
  listReminder,
  saveReminder,
  updateReminder,
  deleteReminder
} from "../../../service/reminder";
import { listPayload, showToast } from "../../../utils/helper";
import { startLoading, stopLoading } from "../../../redux/loader";

const currentDate = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  return `${year}/${month}/${day}`;
};

export const useHome = () => {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.loggedInUser);
  const reminder = useSelector((state) => state.reminder.data);

  const [details, setDetails] = useState();
  const [deleteId, setDeleteId] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isReminderModalOpen, setIsReminderModalOpen] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [selectedReminder, setSelectReminder] = useState(null);

  // pagination start
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);

  const toggleReminder = () => {
    setIsReminderModalOpen(!isReminderModalOpen);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const visibleRows = useMemo(() => {
    return reminder;
  }, [reminder]);

  const isAdmin = useMemo(() => {
    if (loggedInUser && loggedInUser.px_role && ['admin', 'super admin'].includes(loggedInUser.px_role.name.toLowerCase())) {
      return true;
    }
    return false;
  }, [loggedInUser]);

  const isSuperAdmin = useMemo(() => {
    if(loggedInUser && loggedInUser.px_role) {
      return ['super admin'].includes(loggedInUser.px_role.name.toLowerCase());
    }
  }, [loggedInUser]);

  const { control, reset, setValue, handleSubmit } = useForm({
    defaultValues: {
      companyID: loggedInUser?.companyID,
      customerID: "",
      nextDate: moment(new Date()).add(1, 'day').format('yyyy-MM-DD')
    },
    mode: "onBlur",
  });

  const fetchDashboardData = async () => {
    try {
      const params = { currentDate: currentDate() };
      const { success, message, data } = await fetchDashboardDetails(params);
      if (success) {
        setDetails({
          counts: {
            customerCount: data.counts.customerCount,
            companyCount: data.counts.companyCount,
            paymentCount: data.counts.paymentCount,
            billCount: data.counts.billCount,
          }
        });
      } else {
        showToast(message, false);
      }
    } catch (error) {
      showToast(error?.message, false);
    }
  };

  //  fetch customer logic
  const fetchReminderData = async () => {
    try {
      dispatch(startLoading());
      const body = listPayload(page, {companyID: loggedInUser.companyID, isReminder: false});
      const response = await listReminder(body);
      if (response?.statusCode === 200) {
        const payload = response?.data;
        setCount(payload?.count);
        reset({
          companyID: loggedInUser?.companyID,
          customerID: "",
          nextDate: moment(new Date()).add(1, 'day').format('yyyy-MM-DD')
        });
        dispatch(ReminderActions.storeReminder(payload.rows));
      } else if (response?.statusCode === 404) {
        const payload = [];
        dispatch(ReminderActions.storeReminder(payload));
      }
    } catch (error) {
      showToast(error?.message, false);
    } finally {
      dispatch(stopLoading());
    }
  };

  const deleteBtnClickHandler = (id) => {
    setDeleteId(id);
    setIsDeleteModalOpen(true);
  };

  const deleteHandler = async () => {
    try {
      // setIsDeleteModalOpen(false);
      dispatch(startLoading());
      const response = await deleteReminder(deleteId);
      if (response?.statusCode === 200) {
        showToast(response?.message, true);
        dispatch(ReminderActions.removeReminder({ id: deleteId }));
        setCount((prev) => prev - 1);
      } else {
        showToast(response?.messageCode, false);
      }
    } catch (error) {
      showToast(error?.message, false);
    } finally {
      setIsDeleteModalOpen(false);
      dispatch(stopLoading());
    }
  };

  const changeStatusHandler = async (e, id) => {
    try {
      dispatch(startLoading());
      const payload = {
        isReminder: e.target.checked,
        updatedBy: loggedInUser.id,
      };
      const response = await updateReminder(id, payload);
      if (response && response?.statusCode === 200) {
        showToast(response?.message, true);
        fetchReminderData();
        const payload2 = { id, isReminder: payload.isReminder };
        dispatch(ReminderActions.changeReminderStatus(payload2));
      } else {
        showToast(response?.message, false);
      }
    } catch (error) {
      showToast(error?.message, false);
    } finally {
      dispatch(stopLoading());
    }
  };

  const onSubmit = async (data) => {
    try {
      dispatch(startLoading());
      const payload = {
        ...data,
        companyID: loggedInUser.companyID
      }
      const response = selectedReminder === null ? await saveReminder({...payload, createdBy: loggedInUser.id}) : await updateReminder(selectedReminder.id, {...payload, updatedBy: loggedInUser.id});
      if(response && response.statusCode === 200) {
        showToast(response.message, true);
        setSelectReminder(null);
        fetchReminderData();
        setIsReminderModalOpen(false);
      } else {
        showToast(response.message, false);
      }
    } catch (error) {
      showToast(error?.message, false);
    } finally {
      dispatch(stopLoading());
    }
  }

  const handleEdit = (data) => {
    try {
      setSelectReminder(data);
      setIsReminderModalOpen(true);
      setValue("customerID", data.customerID);
      setValue("nextDate", moment(new Date(data.nextDate)).format('yyyy-MM-DD'))
    } catch(error) {
      showToast(error.message, false);
    }
  }

  useEffect(() => {
    (async () => {
      try {
        const body = listPayload(0, { isActive: true }, 1000);
        const responseCustomer = await getCustomerList(body);
        if(responseCustomer && responseCustomer.statusCode === 200) {
          setCustomerList(responseCustomer.data?.rows);
        } else {
          setCustomerList([]);
        }
      } catch(error) {
        showToast(error.message, false);
      }
    })();
  }, []);

  useEffect(() => {
    fetchDashboardData();
    fetchReminderData();
    // eslint-disable-next-line
  }, []);

  return {
    page,
    count,
    control,
    isAdmin,
    details,
    visibleRows,
    customerList,
    isSuperAdmin,
    isDeleteModalOpen,
    isReminderModalOpen,
    onSubmit,
    handleEdit,
    handleSubmit,
    deleteHandler,
    toggleReminder,
    handleChangePage,
    changeStatusHandler,
    setIsDeleteModalOpen,
    deleteBtnClickHandler,
    setIsReminderModalOpen,
  };
};
