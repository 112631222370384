import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { showToast } from "../../../utils/helper";
import { startLoading, stopLoading } from "../../../redux/loader";
import {
    getSettingById,
    saveSetting,
    updateSetting
} from "../../../service/setting";

export const useAddEditSetting = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { id } = useParams();
    const loggedInUser = useSelector((state) => state.loggedInUser);

    const { control, setValue, handleSubmit } = useForm({
        defaultValues: {
            identifier: "",
            value: ""
        },
        mode: "onBlur",
    });

    const isEditMode = useMemo(() => {
        return id && typeof parseInt(id) === 'number' ? true : false
        // eslint-disable-next-line
    }, [id]);

    const onSubmit = async (data) => {
        try {
            dispatch(startLoading());
            let payload = {
                ...data
            };
            if (isEditMode) {
                payload = {
                    ...payload,
                    updatedBy: loggedInUser.id || 0
                }
            } else {
                payload = {
                    ...payload,
                    createdBy: loggedInUser.id || 0
                }
            }
            const response = isEditMode ? await updateSetting(id, payload) : await saveSetting(payload);
            if (response.success) {
                showToast(response?.message || response?.messageCode, true);
                navigate("/setting");
            } else {
                showToast(response?.message || response?.messageCode, false);
            }
        } catch (error) {
            showToast(error?.message, false);
        } finally {
            dispatch(stopLoading());
        }
    }

    const fetchEditSettingData = useCallback(async () => {
        try {
            if (id) {
                dispatch(startLoading());
                const response = await getSettingById(id);
                if (response?.statusCode === 200) {
                    setValue("identifier", response.data.identifier);
                    setValue("value", response.data.value);
                } else {
                    showToast(response?.message, false);
                }
            }
        } catch (error) {
            showToast(error?.message, false);
        } finally {
            dispatch(stopLoading());
        }
    }, [id, dispatch, setValue]);

    const cancelHandler = () => {
        navigate("/setting");
    };

    useEffect(() => {
        if(id) {
            fetchEditSettingData();
        }
        // eslint-disable-next-line
    }, [id]);

    return {
        control,
        isEditMode,
        onSubmit,
        handleSubmit,
        cancelHandler
    }

}