// login apis
export const LOGIN_API = "api/auth/login-via-email";
export const VERIFY_OTP = "api/auth/verify-otp";
export const GET_LOGGED_IN_USER_API = "api/user/get";

// home apis
export const FETCH_DASHBOARD_DETAILS_API = "api/dashboard";

// customer apis
export const CUSTOMER_LIST_API = "api/customer/list";
export const CREATE_CUSTOMER_API = "api/customer";
export const GET_SINGLE_CUSTOMER_API = "api/customer/";
export const UPDATE_CUSTOMER_API = "api/customer/";
export const DELETE_CUSTOMER_API = "api/customer/";
export const CREATE_BULK_CUSTOMER_API = "api/customer/bulk-create";
export const SENT_MEMBERHSIP_OTP = "api/customer/membership-otp";
export const VERIFY_MEMBERSHIP_OTP = "api/customer/membership-verify-otp";
export const SEND_MEMBERHSIP_REDEEM_OTP = "api/customer/membership-redeem-otp";
export const VERIFY_MEMBERSHIP_REDEEM_OTP = "api/customer/membership-redeem-verify-otp";

// payment type apis
export const PAYMENT_TYPE_LIST_API = "api/payment-type/list";
export const CREATE_PAYMENT_TYPE_API = "api/payment-type";
export const DELETE_PAYMENT_TYPE_API = "api/payment-type/";
export const GET_SINGLE_PAYMENT_TYPE_API = "api/payment-type/";
export const UPDATE_PAYMENT_TYPE_API = "api/payment-type/";

// users apis
export const CREATE_USER_API = "api/user";
export const GET_SINGLE_USER_API = "api/user/";
export const USER_LIST_API = "api/user/list";
export const UPDATE_USER_API = "api/user/";
export const DELETE_USER_API = "api/user/";
export const GET_USER_API = "api/user/get";
export const CHANGE_PASSWORD_API = "api/auth/change-password";

// role apis
export const CREATE_ROLE_API = "api/role";
export const GET_SINGLE_ROLE_API = "api/role/";
export const ROLE_LIST_API = "api/role/list";
export const UPDATE_ROLE_API = "api/role/";
export const DELETE_ROLE_API = "api/role/";

// module apis
export const MODULE_LIST_API = "api/module/list";
export const CREATE_MODULE_API = "api/module";
export const DELETE_MODULE_API = "api/module/";
export const GET_SINGLE_MODULE_API = "api/module/";
export const UPDATE_MODULE_API = "api/module/";

// bill apis
export const BILL_LIST_API = "api/bill/list";
export const CREATE_BILL_API = "api/bill";
export const CREATE_BULK_BILL_API = "api/bill/bulk-create";
export const DELETE_BILL_API = "api/bill/";
export const GET_SINGLE_BILL_API = "api/bill/";
export const UPDATE_BILL_API = "api/bill/";

// rights apis
export const RIGHT_LIST_API = "api/right/list";
export const CREATE_BULK_RIGHT_API = "api/right/bulk-create";
export const CREATE_RIGHT_API = "api/right";
export const DELETE_RIGHT_API = "api/right/";
export const GET_SINGLE_RIGHT_API = "api/right/";
export const UPDATE_RIGHT_API = "api/right/";

// reports apis
export const REPORT_LIST_API = "api/report";

export const COMPANY_API = 'api/company';
export const ITEM_API = 'api/item';
export const SETTING_API = 'api/setting';
export const PAYMENT_API = 'api/payment';
export const REMINDER_API = 'api/reminder';
export const WHATSPP_API = 'api/whatsapp'