import React from "react";
import { Controller } from "react-hook-form";

import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import ChangePasswordModal from "../../components/ChangePasswordModal";
import { useAddEditUser } from "./hook/useAddEditUser";

const AddEditUser = () => {
  const {
    role,
    roles,
    userId,
    company,
    control,
    isEditMode,
    isSuperAdmin,
    isChangePasswordOpen,
    handleSubmit,
    onSubmit,
    cancelHandler,
    setIsChangePasswordOpen,
  } = useAddEditUser();

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          className="card"
          sx={{ maxHeight: "calc(100vh - 200px)", overflow: "auto" }}
        >
          <FormGroup className="form-field">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="firstName"
                  control={control}
                  render={({
                    field: { onBlur, onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormControl
                      size="small"
                      variant="standard"
                      className="form-control"
                    >
                      <TextField
                        label="First Name*"
                        size="small"
                        name="firstName"
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value.toUpperCase());
                        }}
                        onBlur={onBlur}
                        error={!!error}
                        helperText={error?.message}
                      />
                    </FormControl>
                  )}
                  rules={{
                    required: "Please Enter First Name",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="lastName"
                  control={control}
                  render={({
                    field: { onBlur, onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormControl
                      size="small"
                      variant="standard"
                      className="form-control"
                    >
                      <TextField
                        label="Last Name*"
                        size="small"
                        name="lastName"
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value.toUpperCase());
                        }}
                        onBlur={onBlur}
                        error={!!error}
                        helperText={error?.message}
                      />
                    </FormControl>
                  )}
                  rules={{
                    required: "Please Enter Last Name",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="userName"
                  control={control}
                  render={({
                    field: { onBlur, onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormControl
                      size="small"
                      variant="standard"
                      className="form-control"
                    >
                      <TextField
                        disabled
                        label="User Name*"
                        size="small"
                        name="userName"
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        // error={!!error}
                        // helperText={error?.message}
                      />
                    </FormControl>
                  )}
                  rules={{
                    required: "Please Enter User Name",
                  }}
                />
              </Grid>
            {/* </Grid>
            <Grid container spacing={2}> */}
              <Grid item xs={12} sm={4}>
                <Controller
                  name={`roleID`}
                  control={control}
                  render={({
                    field: { onBlur, onChange, value },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      freeSolo
                      size="small"
                      id="roleID"
                      options={roles || []}
                      getOptionLabel={(option) => option.name ? option.name : ''}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      value={roles?.find((option) => option.id === value) ?? ''}
                      onBlur={onBlur}
                      onChange={(_event, value) => {
                        if(value) {
                          onChange(value?.id)
                        } else {
                          onChange(null);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Role"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                  rules={{
                    required: "Please Select Role",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="companyID"
                  control={control}
                  render={({
                    field: { onBlur, onChange, value },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      freeSolo
                      size="small"
                      id="companyID"
                      disabled={!isSuperAdmin}
                      options={company || []}
                      getOptionLabel={(option) => option.name ? option.name : ''}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      value={company?.find((option) => option.id === value) ?? ''}
                      onBlur={onBlur}
                      onChange={(_event, value) => {
                        if(value) {
                          onChange(value?.id)
                        } else {
                          onChange(null);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Company"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                  rules={{
                    required: "Please Select Company",
                  }}
                />
              </Grid>
              {!isEditMode && (
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="password"
                    control={control}
                    render={({
                      field: { onBlur, onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormControl
                        size="small"
                        variant="standard"
                        className="form-control"
                      >
                        <TextField
                          label="Password*"
                          size="small"
                          name="password"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          error={!!error}
                          helperText={error?.message}
                        />
                      </FormControl>
                    )}
                    rules={{
                      required: "Please Enter Password",
                    }}
                  />
                </Grid>
              )}
            {/* </Grid>
            <Grid container spacing={2}> */}
              <Grid item xs={12} sm={4}>
                <Controller
                  name="email"
                  control={control}
                  render={({
                    field: { onBlur, onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormControl
                      size="small"
                      variant="standard"
                      className="form-control"
                    >
                      <TextField
                        label="Email*"
                        size="small"
                        name="email"
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!error}
                        helperText={error?.message}
                      />
                    </FormControl>
                  )}
                  rules={{
                    required: "Please Enter Email",
                  }}
                />
              </Grid>
            {/* </Grid>
            <Grid container spacing={2}> */}
              <Grid item xs={12} sm={4}>
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({
                    field: { onBlur, onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormControl
                      size="small"
                      variant="standard"
                      className="form-control"
                    >
                      <TextField
                        type="number"
                        label="Phone*"
                        size="small"
                        name="phoneNumber"
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!error}
                        helperText={error?.message}
                      />
                    </FormControl>
                  )}
                  rules={{
                    required: "Please Enter Phone",
                    pattern: {
                      value: /^\d{10}$/,
                      message: "please enter at leats 10 digits.",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </FormGroup>
        </Box>

        <Grid container spacing={3} sx={{ marginTop: "6px" }}>
          {isEditMode && role === 'admin' && (
            <Grid item md={3}>
              <Button
                type="button"
                className="btn btn-tertiary"
                onClick={() => setIsChangePasswordOpen(true)}
              >
                Change password
              </Button>
            </Grid>
          )}
          <Grid item md={1.5}>
            <Button type="submit" className="btn btn-tertiary">
              {isEditMode ? "Update" : "Save"}
            </Button>
          </Grid>
          <Grid item md={1.5}>
            <Button className="btn btn-cancel" onClick={cancelHandler}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>

      {isChangePasswordOpen && role === "admin" && (
        <ChangePasswordModal
          isChangePasswordOpen={isChangePasswordOpen}
          setIsChangePasswordOpen={setIsChangePasswordOpen}
          userId={userId}
        />
      )}
    </>
  );
};

export default AddEditUser;
